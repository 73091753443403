import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import { Service, SortOptionsMap } from '../../../utils/Services';
import { DialogDropdownMultiple, DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';
import { ReleaseYearContainer } from '../Sources.css';
import icons from '../../../assets/images/icons';
import { OptionSection, OptionsToggle } from '../../Modules/Dialogs/NewModule.css';
import usePrevious from '../../../hooks/usePrevious';
import { fetchCountryCodes, fetchLanguageCodes, LanguagesState } from '../../../redux/slices/languagesSlice';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { MultiLineTextField } from '../../common/MultiLineTextField/MultiLineTextField';
import { assetTagsRegex } from '../../AssetManager/Dialogs/NewAsset';

type PlatformAssetsSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        pathParamsValues: any;
        bodyParamsValues: any;
        errors: { method?: string; name?: string; param?: string; bodyParams?: any; pathParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
        setBodyParamsValues: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

export const PlatformassetsMethods = {
    assetstore_filter: {
        name: 'Tag Filter',
        filters: ['releaseYear', 'tags'],
        sort: true
    },
    assetstore_search: {
        name: 'Search (client)',
        filters: [],
        sort: false
    }
};

export const PlatformMethodKeys = {
    ASSETSTORE_FILTER: 'assetstore_filter',
    ASSETSTORE_SEARCH: 'assetstore_search'
};

const PlatformAssetsSourceFields: FC<PlatformAssetsSourceFieldsProps> = ({
    service,
    dropdownOptions: { methodOptions },
    fieldValues: { selectedMethod, bodyParamsValues, pathParamsValues, errors },
    fieldSetters: { setSelectedMethod, setQueryParamsValues, setPathParamsValues, setBodyParamsValues, setErrors }
}) => {
    const { languageCodes, countryCodesAssetManager }: LanguagesState = useAppSelector((state) => state.languages);
    const dispatch = useDispatch();

    const [showFiltersAlways, setShowFiltersAlways] = useState(false);
    const previousPathParams = usePrevious(pathParamsValues);

    const loadLanguageCodes = async () => {
        return await dispatch(fetchLanguageCodes()).unwrap();
    };

    const loadCountryCodes = async () => {
        return await dispatch(fetchCountryCodes(true)).unwrap();
    };

    useEffect(() => {
        !languageCodes?.length && loadLanguageCodes();
        !countryCodesAssetManager?.length && loadCountryCodes();
    }, []);

    useEffect(() => {
        if (!selectedMethod || !bodyParamsValues) return;
        if (!bodyParamsValues.sort && selectedMethod !== PlatformMethodKeys.ASSETSTORE_SEARCH) {
            const defaultSort = 'releaseYear[asc]';
            setBodyParamsValues((values: any) => ({ ...values, sort: defaultSort }));
        }
    }, [bodyParamsValues]);

    useEffect(() => {
        if (pathParamsValues && previousPathParams && pathParamsValues.type !== previousPathParams.type) {
            setBodyParamsValues({});
        }
    }, [pathParamsValues]);

    useEffect(() => {
        if (!selectedMethod) return;
        const assetType = service.methods.find((method) => method.key === selectedMethod)?.params?.path?.assetType?.[0] || '';
        setPathParamsValues((values: any) => ({ ...values, assetType }));
    }, [selectedMethod]);

    const renderLanguagesAndCountry = () => {
        const countryOptions =
            countryCodesAssetManager?.map((countryCode) => {
                const code = countryCode.code.toUpperCase();

                return {
                    value: code,
                    label:
                        countryCode.name
                            .toString()
                            .split('_')
                            .map((s: string) => (s !== 'and' ? _.capitalize(s) : 'and'))
                            .join(' ') + ` (${code})`
                };
            }) || [];

        const languagesOptions =
            languageCodes?.map((lang) => {
                return {
                    value: lang.code,
                    label: `${lang.name} (${lang.code})`
                };
            }) || [];

        let countrySelect = null;

        countrySelect = (
            <DialogDropdownSingle
                value={countryOptions?.find((opt: any) => opt.value === bodyParamsValues?.country) || ''}
                options={countryOptions}
                placeholder={'Select Country'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'country']));
                    setBodyParamsValues((values: any) =>
                        !value.value ? { language: values.language, country: '' } : { ...values, country: value.value }
                    );
                }}
                error={errors.bodyParams?.country}
                isDisabled={!countryCodesAssetManager?.length}
                clearable
            />
        );

        const languageSelect = (
            <DialogDropdownSingle
                value={languagesOptions?.find((opt: any) => opt.value === bodyParamsValues?.language) || ''}
                options={languagesOptions}
                placeholder={'Select Language'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'language']));
                    setBodyParamsValues((values: any) => ({ ...values, language: value.value }));
                }}
                error={errors.bodyParams?.language}
                isDisabled={!languageCodes.length}
                clearable
                optional
            />
        );

        return (
            <>
                {countrySelect}
                {languageSelect}
            </>
        );
    };

    const renderSortField = () => {
        const sortOptions = service.methods
            .find((method) => method.key === selectedMethod)
            ?.params.body?.sort?.map((sortString: string) => ({
                value: sortString,
                label: _.get(SortOptionsMap, sortString, sortString)
            }));

        return (
            <DialogDropdownSingle
                value={sortOptions?.find((opt: any) => opt.value === bodyParamsValues?.sort)}
                options={sortOptions}
                placeholder={'Select Sorting'}
                onChange={(selectedSortOpt: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'sort']));
                    setBodyParamsValues((values: any) => ({ ...values, sort: selectedSortOpt.value }));
                }}
                error={errors.bodyParams?.sort}
                withTopMargin
            />
        );
    };

    const renderFilterFields = () => {
        const methodFilters: string[] = _.get(PlatformassetsMethods, [selectedMethod, 'filters'], []);
        const methodParams = service.methods.find((m) => m.key === selectedMethod)?.params?.body;

        if (!methodFilters?.length || !methodParams) return null;

        const fields: JSX.Element[] = [];

        const renderArrayFields = (
            filterObj: any,
            filter: string,
            key: string,
            index: number,
            allFields?: any[],
            hideFilters?: boolean
        ) => {
            const arrayFields: JSX.Element[] = [];
            if (hideFilters) {
                arrayFields.push(renderShowFiltersToggle(allFields));
                return arrayFields;
            }

            const options = filterObj.map((elem: any) => ({
                value: elem,
                label: _.get(SortOptionsMap, elem, _.capitalize(elem))
            }));

            arrayFields.push(
                <DialogDropdownMultiple
                    key={`array-field-${index}`}
                    value={options?.filter((opt: any) => bodyParamsValues?.[key]?.includes(opt.value))}
                    options={options}
                    placeholder={`Select ${filter}`}
                    labelText={`Select ${filter}`}
                    onChange={(value: any) => {
                        setBodyParamsValues((values: any) => ({ ...values, [key]: value.map((elem: any) => elem.value) }));
                    }}
                    toolTipText={undefined}
                    optional
                    allowSelectAll
                />
            );

            return arrayFields;
        };

        const renderYearFields = (filter: string, key: string, index: number, hideFilters?: boolean) => {
            const yearFields: JSX.Element[] = [];
            // if we need to hide the filters, we move on
            if (hideFilters) return yearFields;

            const startKey = `${key}.start`;
            const endKey = `${key}.end`;
            const startDate = bodyParamsValues?.[startKey];
            const endDate = bodyParamsValues?.[endKey];
            const currentYear = new Date().getFullYear();
            const startOptions: { label: string; value: number }[] = [];
            const endOptions: { label: string; value: number }[] = [];
            for (let i = currentYear; i >= 1950; i--) {
                if (!endDate || endDate >= i) {
                    startOptions.push({
                        value: i,
                        label: i.toString()
                    });
                }
                if (!startDate || startDate <= i) {
                    endOptions.push({
                        value: i,
                        label: i.toString()
                    });
                }
            }
            yearFields.push(
                <ReleaseYearContainer>
                    <DialogDropdownSingle
                        key={`date-field-${index}-start`}
                        value={startOptions?.find((opt) => bodyParamsValues?.[startKey] === opt.value)}
                        options={startOptions}
                        placeholder={`Select ${_.startCase(filter)} Start`}
                        labelText={`Select ${_.startCase(filter)} Start`}
                        toolTipText={'Date will be calculated from the start of the selected year'}
                        onChange={(value: any) => setBodyParamsValues((values: any) => ({ ...values, [startKey]: value.value }))}
                        optional
                        clearable
                        notSorted
                    />
                    <DialogDropdownSingle
                        key={`date-field-${index}-end`}
                        value={endOptions?.find((opt) => bodyParamsValues?.[endKey] === opt.value)}
                        options={endOptions}
                        placeholder={`Select ${_.startCase(filter)} End`}
                        labelText={`Select  ${_.startCase(filter)} End`}
                        toolTipText={'Date will be calculated to the end of the selected year'}
                        onChange={(value: any) => setBodyParamsValues((values: any) => ({ ...values, [endKey]: value.value }))}
                        optional
                        clearable
                        notSorted
                    />
                </ReleaseYearContainer>
            );

            return yearFields;
        };

        const renderTagsField = (key: string) => {
            return (
                <MultiLineTextField
                    value={bodyParamsValues?.[key] || ''}
                    onChange={(evt: any) => {
                        const escapedValue = evt.target.value.replace(/\s+/g, '');
                        setBodyParamsValues((values: any) => ({ ...values, [key]: evt.target.value }));
                        if (!!escapedValue && !assetTagsRegex.test(escapedValue)) {
                            setErrors((errors: any) => {
                                const newErrors = { ...errors };
                                if (!newErrors.bodyParameters) {
                                    newErrors.bodyParams = {};
                                }

                                newErrors.bodyParams['filter.tags'] = 'Please add a valid value.';
                                return newErrors;
                            });
                        } else setErrors(_.omit(errors, ['bodyParams', 'filter.tags']));
                    }}
                    toolTipText={`Please add tags in the format: 'key:value,key:value'. Whitespace and newline is allowed but will be deleted at save.`}
                    label={'Tags'}
                    placeholder={'Tags'}
                    error={errors.bodyParams?.['filter.tags']}
                    withTopMargin
                    optional
                    withoutSanitization
                />
            );
        };

        const renderShowFiltersToggle = (allFields?: JSX.Element[]) => {
            // if we don't need to hide the filters or we already have an options section, we move on
            if (!_.get(PlatformassetsMethods, selectedMethod)?.hideFilters || allFields?.find((el) => el.key === 'options-section'))
                return <></>;

            return (
                <OptionSection key={'options-section'}>
                    <OptionsToggle onClick={() => setShowFiltersAlways(!showFiltersAlways)}>
                        {!showFiltersAlways ? 'Show' : 'Hide'} Optional Filters{' '}
                        <SVGInline src={showFiltersAlways ? icons.arrowUpIcon : icons.arrowDownIcon} />
                    </OptionsToggle>
                </OptionSection>
            );
        };

        const hideFilters = _.get(PlatformassetsMethods, selectedMethod)?.hideFilters && !showFiltersAlways;
        methodFilters.forEach((filter, index) => {
            const key = `filter.${filter}`;
            const filterObj = _.get(methodParams, key, '');
            if (!filterObj) return;

            if (Array.isArray(filterObj)) {
                fields.push(...renderArrayFields(filterObj, filter, key, index, fields, hideFilters));
                return;
            }

            if (filter.toLocaleLowerCase().includes('year')) {
                fields.push(...renderYearFields(filter, key, index, hideFilters));
                return;
            }

            if (filter.toLocaleLowerCase().includes('tags')) {
                fields.push(renderTagsField(key));
                return;
            }

            if (hideFilters) return;

            fields.push(
                <DialogTextField
                    key={`text-field-${index}`}
                    value={bodyParamsValues?.[key] || ''}
                    label={`Insert ${filter}`}
                    placeholder={`Insert ${filter}`}
                    onChange={(evt: any) => {
                        setBodyParamsValues((values: any) => ({ ...values, [key]: evt.target.value }));
                    }}
                    optional
                />
            );
        });

        return fields;
    };

    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source: any) => source.value === selectedMethod) || ''}
                options={methodOptions}
                placeholder={'Select Method'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, 'method'));
                    setSelectedMethod(value.value);
                    setQueryParamsValues({});
                    setBodyParamsValues({});
                }}
                error={errors.method}
            />

            {selectedMethod && selectedMethod !== PlatformMethodKeys.ASSETSTORE_SEARCH && (
                <>
                    {renderLanguagesAndCountry()}
                    {bodyParamsValues?.country?.length ? (
                        <>
                            {renderSortField()}
                            {renderFilterFields()}
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default PlatformAssetsSourceFields;
