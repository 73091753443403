import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import { ChangePasswordFieldsWrapper, ChangePasswordLabel } from '../Profile.css';
import { DialogTextField } from '../../Dialog/GenericDialog';
import { validator } from '../../../../utils/fnValidator';
import { IconButton, InputAdornment } from '@material-ui/core';
import icons from '../../../../style';

type ChangePasswordFieldsProps = {
    initialValues: ChangePasswordValues;
    initialErrors?: ChangePasswordValues;
    onChange: (arg: ChangePasswordValues, arg2?: ChangePasswordValues) => void;
};

export type ChangePasswordValues = {
    oldPassword: string;
    newPassword: string;
    passwordConfirm: string;
};

const ChangePasswordFields: FC<ChangePasswordFieldsProps> = ({ initialValues, onChange, initialErrors }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [values, setValues] = useState<ChangePasswordValues>(initialValues);
    const [errors, setErrors] = useState<ChangePasswordValues & { repeat?: string }>(
        initialErrors || {
            oldPassword: '',
            newPassword: '',
            passwordConfirm: ''
        }
    );
    const [showPasswords, setShowPasswords] = useState<any>({});

    useEffect(() => {
        initialErrors && setErrors(initialErrors);
    }, [initialErrors]);

    const handleChange = (value: string, key: keyof ChangePasswordValues) => {
        const newValues = {
            ...values,
            [key]: value
        };
        const newErrors = {
            ...errors,
            [key]: validator({ required: true, minLength: 8, password: true }, value)
        };
        if (!newErrors.passwordConfirm && !newErrors.newPassword) {
            if (newValues.passwordConfirm && newValues.newPassword && newValues.passwordConfirm !== newValues.newPassword) {
                newErrors.repeat = 'Passwords do not match!';
            } else {
                delete newErrors.repeat;
            }
        }
        setValues(newValues);
        setErrors(newErrors);
        onChange(newValues, Object.values(newErrors).some((val) => !!val) ? newErrors : undefined);
    };

    const passwordAdornment = (key: string) => (
        <InputAdornment position="end">
            <IconButton onClick={() => setShowPasswords({ ...showPasswords, [key]: !showPasswords[key] })}>
                <SVGInline src={showPasswords[key] ? icons.hidePasswordIcon : icons.showPasswordIcon} />
            </IconButton>
        </InputAdornment>
    );

    return (
        <ChangePasswordFieldsWrapper>
            <ChangePasswordLabel
                onClick={() => {
                    setIsOpen(!isOpen);
                    const blankValues = {
                        oldPassword: '',
                        newPassword: '',
                        passwordConfirm: ''
                    };
                    setValues(blankValues);
                    setErrors(blankValues);
                    onChange(blankValues, blankValues);
                }}
            >
                Change Password
            </ChangePasswordLabel>

            {isOpen && (
                <>
                    <DialogTextField
                        InputProps={{ endAdornment: passwordAdornment('oldPassword') }}
                        label="Old Password"
                        value={values.oldPassword}
                        onChange={(evt: any) => handleChange(evt.target.value, 'oldPassword')}
                        type={showPasswords['oldPassword'] ? 'text' : 'password'}
                        error={errors.oldPassword}
                        withTopMargin
                        withoutSanitization
                    />
                    <DialogTextField
                        InputProps={{ endAdornment: passwordAdornment('newPassword') }}
                        label="New Password"
                        value={values.newPassword}
                        onChange={(evt: any) => handleChange(evt.target.value, 'newPassword')}
                        type={showPasswords['newPassword'] ? 'text' : 'password'}
                        error={errors.newPassword}
                        withTopMargin
                        withoutSanitization
                    />
                    <DialogTextField
                        InputProps={{ endAdornment: passwordAdornment('passwordConfirm') }}
                        label="Confirm New Password"
                        value={values.passwordConfirm}
                        onChange={(evt: any) => handleChange(evt.target.value, 'passwordConfirm')}
                        type={showPasswords['passwordConfirm'] ? 'text' : 'password'}
                        error={errors.passwordConfirm || errors.repeat}
                        withTopMargin
                        withoutSanitization
                    />
                </>
            )}
        </ChangePasswordFieldsWrapper>
    );
};

export default ChangePasswordFields;
