export type RenamedKeysType = {
    pages: {
        [key: string]: string;
    };
    modules: {
        [key: string]: string;
    };
    items: {
        [key: string]: string;
    };
    dynamicSources: {
        [key: string]: string;
    };
    menus: {
        [key: string]: string;
    };
    pageStyles: {
        [key: string]: string;
    };
    audiences: {
        [key: string]: string;
    };
    settings: {
        [key: string]: string;
    };
    displayConditions: {
        [key: string]: string;
    };
};

export const validateVersionFormat = (version: string) => {
    const parts = version.split('.');
    if (parts.length > 4) return false;

    for (const part of parts) {
        if (!/^-?\d+$/.test(part) && part !== 'x') return false;
    }

    // after x in the format, only x is accepted (no numbers, for ex: 1.2.x.2 is incorrect)
    let firstXIndex = parts.indexOf('x');
    if (firstXIndex >= 0) return !parts.some((part, i) => i >= firstXIndex && part !== 'x');
    return true;
};

export const capitalizeAndSplitCamelCaseString = (s: string) => {
    let str = `${s[0].toUpperCase()}${s.substring(1)}`;
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const sanitizeFileName = (name: string) => {
    return name.trim().replace(/\s+/g, '_');
};

const renamedKeys: RenamedKeysType = {
    pages: {
        name: 'Page Name',
        intent: 'Page Intent',
        pageStyleId: 'Page Style',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        slug: 'Slug',
        canonical: 'Canonical',
        description: 'Description',
        moduleIds: 'Modules',
        backgroundImage: 'Page Background Image',
        increasedTopMargin: 'Increased Top Margin',
        locked: 'Locked',
        publishAt: 'Scheduled Publishing'
    },
    modules: {
        name: 'Module Name',
        template: 'UI Template',
        itemIds: 'Items',
        pinnable: 'Pinnable',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        includeProviderLogoCard: 'Provider Logo Card',
        invisible: 'Hide Module on Page',
        hideContentMarker: 'Hide Content Marker',
        contentWorld: 'Content World',
        preview: 'Preview',
        topTen: 'Top X',
        autoCollection: 'Auto-Collection',
        autoScroll: 'Auto-Scroll',
        placed: 'Placed',
        slug: 'Slug',
        viewAllCardPlacement: 'View All Card Placement',
        alterateBehaviour: 'Alterate Behaviour',
        alterateBehaviourLabel: 'Alterate Behaviour Label'
    },
    items: {
        name: 'Item Name',
        placed: 'Placed',
        conditionIds: 'Target Conditions',
        contentWorldType: 'Content World Type',
        videoForAutoplay: 'Video For Auto-Play',
        logo: 'Logo',
        title: 'Title',
        subtitle: 'Subtitle',
        description: 'Description',
        actionName: 'Action Name',
        action: 'Action',
        dynamicSourceId: 'Source',
        backgroundImage: 'Item Background Image',
        heroBackgroundImage: 'Item Hero Background Image',
        maxNumberOfItems: 'Maximum Number of Items',
        alterateBehaviour: 'Alterate Behaviour',
        alterateBehaviourLabel: 'Alterate Behaviour Label',
        singleAsset: 'Single Asset',
        videoAutoplay: 'Video Auto-Play',
        abTestingGroupIds: 'AB Testing Groups'
    },
    dynamicSources: {
        name: 'Source Name',
        service: 'Service',
        method: 'Method',
        subService: 'Sub Service',
        queryParameters: 'Query Parameter',
        pathParameters: 'Path Parameter',
        bodyParameters: 'Body Parameter',
        channelId: 'Channel Id',
        assetType: 'Asset Type',
        moduleType: 'Module Type',
        sort: 'Sort',
        collapse: 'Collapse'
    },
    menus: {
        name: 'Menu Name',
        items: 'Menu Items',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        bindToPages: 'Bind to Pages',
        publishAt: 'Scheduled Publishing'
    },
    pageStyles: {
        name: 'Page Style Name',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        colors: 'Colors',
        increasedTopMargin: 'Increased Top Margin',
        pageLogo: 'Page Logo',
        pageBackgroundImage: 'Page Background Image',
        contentMarkers: 'Content Markers'
    },
    audiences: {
        name: 'Audience Name',
        conditionIds: 'Target Conditions',
        description: 'Audience Description',
        targetGroupId: 'Target Group',
        deviceClass: 'Device Class',
        deviceType: 'Device Type',
        operatingSystem: 'Operating System',
        operatingSystemVersion: 'Operating System Version',
        clientAppVersion: 'Client App Version',
        clientAppVariants: 'Client App Variant',
        deviceIds: 'Device ID',
        userIds: 'User ID',
        cableOperator: 'Cable Operator',
        location: 'Location',
        manufacturer: 'Manufacturer',
        country: 'Country'
    },
    settings: {
        name: 'Settings Name',
        conditionIds: 'Target Conditions',
        abTestingGroupIds: 'AB Testing Groups',
        publishAt: 'Scheduled Publishing',

        // PIN SETTINGS FIELDS
        allowedWrongAttempts: 'Allowed Wrong Attempts',
        wrongAttemptsLockDuration: 'Wrong Attempts Lock Duration',

        // MISCELLANEOUS
        appStartup: 'App Startup',
        channelGenresList: 'Channel Genres List',
        gatewayUrl: 'Gateway URL',
        epgOverCdn: 'EPG Over CDN',
        opVault: 'OPVault',
        deviceProfileMechanism: 'Device Profile Mechanism',
        brandId: 'Brand ID',
        faq: 'FAQ',
        analyticsEndpointUrl: 'Analytics Endpoint',
        epgPastVisibility: 'EPG Past Visibility',
        epgFutureVisibility: 'EPG Future Visibility',
        kalturaChannelFilterCollectionId: 'Kaltura Channel Filter Collection ID',
        regionalization: 'Regionalization',
        playerResizeMode: 'Player Resize Mode',

        // LOGIN SETTINGS FIELDS
        silentLogin: 'Silent Login',
        qrLogin: 'QR Login',
        credentialLogin: 'Credential Login',
        credentialLoginForgotPasswordUrl: 'Forgot Password URL',
        qrLoginCodeUrl: 'URL',

        // CUSTOMER SETTINGS FIELDS
        signupUrl: 'Signup URL',
        errorCodeUrl: 'Error Code URL',
        thirdPartyActivationUrl: '3rd Party Activation URL',

        // PLAYBACK SETTINGS FIELDS
        defaultVodSubtitleHandling: 'Default VOD Subtitle Handling',

        // FORCED UPGRADE SETTINGS FIELDS
        forcedUpgrade: 'Forced Upgrade',
        minimumVersion: 'Minimum App Version',

        // USER PROFILES SETTINGS FIELDS
        userProfiles: 'User Profiles',
        // CONTENT RATINGS SETTINGS FIELDS
        contentRatings: 'Content Ratings'
    },
    displayConditions: {
        name: 'Display Condition Name',
        placed: 'Placed',
        weekDays: 'Week Days',
        dateInterval: 'Start & End Date',
        timeInterval: 'Start & End Time',
        languages: 'Language',
        countries: 'Country',
        ageRating: 'Age Rating',
        subscriptionStatus: 'Subscribed',
        happinessScore: 'Happiness Score',
        persona: 'Persona',
        guestMode: 'User Status',
        coldStart: 'Cold Start',
        segments: 'Segments',
        dataProtectionRegulation: 'Data Protection Regulation',
        state: 'State',
        city: 'City'
    }
};

export const formatObjectActivityKey = (objectType: keyof RenamedKeysType, key: string) => {
    return renamedKeys[objectType][key] || key;
};
