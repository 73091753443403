import { DynamicSource } from './DynamicSource';
import { Project } from './Project';
import { Tenant } from './Tenant';
import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';
import { ScreenTypes } from './PageStyle';

export type Item = {
    _id: string;
    name: string;
    tenantId: string;
    projectId: string;
    lastModified?: number;
    modifiedBy?: any;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    tenant?: Tenant;
    project?: Project;
    itemType: itemTypes;
    conditionIds?: string[];
    abTestingGroupIds?: string[];
    backgroundImage?: ScreenTypes;
    backgroundColor?: string;
    heroBackgroundColor?: string;
    heroBackgroundImage?: ScreenTypes;
    videoAutoplay?: boolean;
    videoForAutoplay?: string;
    logo?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    actionName?: string;
    action?: any;
    dynamicSourceId?: string;
    dynamicSource?: DynamicSource;
    contentSourceType?: contentSourceTypes;
    contentSource?: any;
    maxNumberOfItems?: number;
    conditions?: any[];
    alterateBehaviour?: boolean;
    alterateBehaviourLabel?: string;
    singleAsset?: string;
    contentWorldType?: string;
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
    published?: boolean;
    publishStatus?: PUBLISHED_STATUS;
    deactivated?: boolean;
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    adminLocked?: boolean;
};

export enum ContentWorldTypes {
    SELECTABLE = 'selectable',
    PRESELECTED = 'preselected',
    REQUIRED = 'required'
}

export const contentWorldToolTips = {
    [ContentWorldTypes.SELECTABLE]: 'Content Worlds which can be freely added and removed to Favorites',
    [ContentWorldTypes.PRESELECTED]: 'Recommended Content World which can be removed from the Favorites by the end user',
    [ContentWorldTypes.REQUIRED]: 'Mandatory Content World which can’t be removed from the Favorites by the user'
};

export enum itemTypes {
    DYNAMIC = 'dynamic',
    EDITORIAL = 'editorial'
}

export enum contentSourceTypes {
    YOUTUBE = 'youtube',
    YOUTUBE_RECO = 'youtube_reco',
    PRIME_VIDEO = 'prime_video',
    ALLENTE_GATEWAY = 'allentegateway',
    ALLENTE_GATEWAY_STAGING = 'allentegatewaystaging',
    ALLENTE_GATEWAY_STB = 'allentegatewaystb',
    ALLENTE_LOCAL_STB = 'allentelocalstb',
    STINGRAY_KARAOKE = 'stingray_karaoke',
    ANDROID = 'android',
    KALTURA = 'kaltura',
    KALTURA_STAGING = 'kaltura_staging',
    '3READY_ASSETS' = '3ready_assets',
    RECORDINGS = 'recordings',
    DEUTSCHE_WELLE = 'deutsche_welle',
    HORIZON_SPORTS = 'horizon_sports',
    MOTOR_RACING = 'motor_racing',
    SWR3 = 'swr3',
    TED_TALKS = 'ted_talks'
}

export const RealDynamicContentSourceTypes = [
    contentSourceTypes.YOUTUBE,
    contentSourceTypes.YOUTUBE_RECO,
    contentSourceTypes.ALLENTE_GATEWAY,
    contentSourceTypes.ALLENTE_GATEWAY_STAGING,
    contentSourceTypes.ALLENTE_GATEWAY_STB,
    contentSourceTypes.ALLENTE_LOCAL_STB,
    contentSourceTypes.ANDROID,
    contentSourceTypes.KALTURA,
    contentSourceTypes.KALTURA_STAGING,
    contentSourceTypes.RECORDINGS,
    contentSourceTypes['3READY_ASSETS'],
    contentSourceTypes.DEUTSCHE_WELLE,
    contentSourceTypes.HORIZON_SPORTS,
    contentSourceTypes.MOTOR_RACING,
    contentSourceTypes.SWR3,
    contentSourceTypes.TED_TALKS
];

export const ContentActionTypes = {
    PAGELINK: 'PAGELINK',
    DEEPLINK: 'DEEPLINK',
    APPLINK: 'APPLINK',
    PLAYVIDEO: 'PLAYVIDEO',
    PAGELINK_WITH_TEASER: 'PAGELINK_WITH_TEASER',
    INTENT: 'INTENT',
    NOACTION: 'NOACTION'
} as const;

export type ContentActionType = typeof ContentActionTypes[keyof typeof ContentActionTypes];
