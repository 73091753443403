import React, { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../common/Dialog/GenericDialog';
import {
    ContentRatingSettings,
    DefaultSettingValues,
    Setting,
    SettingSectionType,
    SettingsValues,
    specificDefaultSettingValues
} from '../../../types/Setting';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { fetchSettingType, settingsState } from '../../../redux/slices/settingsSlice';
import _ from 'lodash';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';

export type NewSettingDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: Setting) => void;
};

export const NewSettingDialog: FC<NewSettingDialogProps> = ({ open, onClose, onSave }) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { settingType, settingTypeLoading: loading }: settingsState = useAppSelector((state) => state.settings);

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [conditionIds, setConditionIds] = useState<string[]>([]);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<string[]>([]);
    const [defaultSetting, setDefaultSetting] = useState<SettingsValues>({});

    const loadSettingType = async () => {
        return await dispatch(fetchSettingType(activeTenantId || '')).unwrap();
    };

    const handleCloseClick = () => {
        setName('');
        setConditionIds([]);
        setAbTestingGroupIds([]);
        onClose();
    };

    const handleSaveClick = () => {
        const newSetting: Setting = {
            _id: '',
            name: name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            conditionIds: conditionIds,
            abTestingGroupIds: abTestingGroupIds,
            ...defaultSetting,
            contentRatings: undefined
        };

        onSave(newSetting);
        handleCloseClick();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleSaveClick,
        disabled: loading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    useEffect(() => {
        if (open && _.isEmpty(settingType)) {
            loadSettingType();
        }
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (_.isEmpty(settingType)) return;

        const defaultSettingValues: SettingsValues = {};
        (Object.keys(settingType) as Array<SettingSectionType>).forEach((key) => {
            const subSections = settingType[key] || {};
            const defaultSubSection: any = {};

            Object.keys(subSections).forEach((sKey) => {
                const subSectionUM = subSections[sKey];
                defaultSubSection[sKey] = defaultSubSection[sKey] =
                    specificDefaultSettingValues?.[sKey] ?? DefaultSettingValues[subSectionUM];
            });
            defaultSettingValues[key] = defaultSubSection;
        });

        setDefaultSetting(defaultSettingValues);
    }, [settingType]);

    if (!isOpen) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={'Create Setting'}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.settings }}
            dataCy={'new-setting-dialog'}
        >
            <DialogTextField
                label={'Settings Name'}
                value={name}
                onChange={(evt: any) => {
                    setName(evt.target.value);
                }}
                placeholder={'Settings Name'}
                dataCy={'setting-name-input'}
            />

            <TargetConditionsSelect
                targets={conditionIds}
                onChange={(val) => {
                    setConditionIds(val);
                }}
                inDialog
                withTopMargin
                hideCreateNew
            />
            <ABTestingGroupSelect
                inDialog
                withTopMargin
                selectedGroups={abTestingGroupIds}
                onChange={(newValues) => setAbTestingGroupIds(newValues)}
                selectedTargetConditions={conditionIds}
            />
        </GenericDialog>
    );
};
