import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';
import { Project } from './Project';
import { Tenant } from './Tenant';

export type Menu = {
    _id: string;
    name: string;
    type: MenuTypes;
    items: MenuItem[];
    bindToPages: boolean;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    conditionIds?: string[];
    conditions?: any[];
    lastModified?: number;
    modifiedBy?: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    locked?: string;
    published?: boolean;
    publishAt?: number;
    publishStatus?: PUBLISHED_STATUS;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
    abTestingGroupIds?: string[];
};

export enum MenuTypes {
    MAIN = 'main',
    CUSTOMER_SERVICE = 'customerService',
    SUBMENU = 'submenu'
}

export enum MenuItemTypes {
    APPLICATION = 'application',
    PAGE = 'page'
}

export type MenuItemPage = {
    title: string;
    type: MenuItemTypes.PAGE;
    pageId: string;
    icon?: string;
};

export type MenuItemApplication = {
    title: string;
    type: MenuItemTypes.APPLICATION;
    package: string;
    icon?: string;
};

export interface CustomerMenuItem {
    title: string;
    subtitle: string;
    url: string;
}

export type MenuItem = MenuItemApplication | MenuItemPage | CustomerMenuItem;
