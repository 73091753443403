import React, { useEffect, useState } from 'react';
import { DIALOG_NAMES, DIALOG_TITLES_MAP } from '../../../utils/fnDialogs';
import { DialogTextField } from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { FormDescription, FormSubtitle, FormTitle, FormWrapper, sendForgotPasswordStyle } from '../Login.css';
import Button from '../../Buttons/Button/Button';

export type ForgotPasswordProps = {
    onSend: any;
    errorMsg: string;
};

const ForgotPasswordDialog: React.FC<ForgotPasswordProps> = ({ onSend, errorMsg }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(errorMsg);

    useEffect(() => {
        setError(errorMsg);
    }, [errorMsg]);

    const validateEmail = () => {
        return !!!error;
    };

    const handleSendClick = (evt: any) => {
        evt.preventDefault();
        if (onSend) {
            if (validateEmail()) {
                onSend(email);
            }
        }
    };

    const { title, text, subtitle } = DIALOG_TITLES_MAP.get(DIALOG_NAMES.FORGOT_PASSWORD) ?? {};
    return (
        <FormWrapper>
            <FormTitle>{title}</FormTitle>
            <FormSubtitle>{subtitle}</FormSubtitle>
            <FormDescription>{text}</FormDescription>
            <DialogTextField
                value={email}
                error={error}
                label={'Enter your email'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(evt.target.value);
                    setError(
                        validator(
                            {
                                required: true,
                                minLength: 6,
                                email: true
                            },
                            evt.target.value
                        )
                    );
                }}
                onKeyPress={(evt: any) => {
                    if (evt.key === 'Enter') handleSendClick(evt);
                }}
                withoutSanitization
            />
            <Button onClick={handleSendClick} id="forgot-button" label={'Send'} type="BLUE" style={sendForgotPasswordStyle} />
        </FormWrapper>
    );
};

export default ForgotPasswordDialog;
