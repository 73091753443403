import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchContentActionTypes, fetchDeeplinkOptions, ItemState } from '../../../redux/slices/itemSlice';
import { validator } from '../../../utils/fnValidator';

import GenericDialog, {
    DialogButton,
    DialogDropdownSingle,
    DialogTextField,
    DialogTypes,
    notSupportedExtensionAlert
} from '../../common/Dialog/GenericDialog';
import { createPage, fetchAllPages, pagesState } from '../../../redux/slices/pagesSlice';
import { EMPTY_WORD_STRING, getImgixUrl, getVideoCdnUrl } from '../../../utils/Globals';
import { NewPageDialog } from '../../PageEdit/Dialogs/NewPageDialog';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { Page } from '../../../types/Page';
import { fetchAllModules, ModulesState } from '../../../redux/slices/moduleSlice';
import { DropdownOptionWrapper, TruncatedText } from '../../../style/styled-components/reusable.css';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import { renderPublishStatusIcon } from '../../../utils/fnPublish';
import icons from '../../../assets/images/icons';
import { ActionFieldsToggle } from '../Items.css';
import ObjectNameTooltip from '../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../../../types/Object';
import { actionNameMissingAlert, dialogAlert, getPagesSelectOptions } from '../../../utils/fnDialogs';
import {
    ApplicationFieldLabelValues,
    ApplicationFieldTooltips,
    ApplicationFieldTooltipsValues,
    ApplicationValues
} from '../../Applications/ApplicationDialog';
import { ApplicationsState, fetchApplications } from '../../../redux/slices/applicationsSlice';
import { InputAdornment } from '@material-ui/core';
import { FileManagerAdornmentWrapper } from '../../common/Dialog/GenericDialog.css';
import FileManagerDialog from '../../common/Dialog/FileDialogs/FileManagerDialog';
import { imgExtensions, videoExtensions } from '../../FileManager/FileManager';
import { useNavigate } from 'react-router-dom';
import ApplicationSelect from '../../Applications/ApplicationSelect';
import useTranslation from '../../../hooks/useTranslation';
import { MultiLineTextField } from '../../common/MultiLineTextField/MultiLineTextField';
import { FilesState, uploadFilesSync } from '../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL } from '../../../utils/fnUrl';
import tooltips from '../../../utils/tooltips.json';
import { DeeplinkUrlContainer } from '../EditorialView.css';

export type ActionDialogProps = {
    open: boolean;
    action: any;
    name: string | undefined;
    isTemplate?: boolean;
    inGalleryOrButton?: boolean;
    onSave: (actionName: string, action: any) => void;
    onClose: () => void;
};

const itemActions = {
    PAGELINK: 'PAGELINK',
    DEEPLINK: 'DEEPLINK',
    APPLINK: 'APPLINK',
    PLAYVIDEO: 'PLAYVIDEO',
    PAGELINK_WITH_TEASER: 'PAGELINK_WITH_TEASER',
    INTENT: 'INTENT',
    OPEN_POP_UP: 'OPEN_POP_UP',
    OPEN_TEXT_PAGE: 'OPEN_TEXT_PAGE',
    NOACTION: 'NOACTION'
};

export const ActionTypeLabels = {
    DEEPLINK: 'Deeplink URL',
    APPLINK: 'Link to an App',
    PAGELINK: 'Link to a Page',
    PAGELINK_WITH_TEASER: 'Link to a page while displaying a teaser of a module',
    PLAYVIDEO: 'Play a Video',
    OPEN_POP_UP: 'Open Pop-up',
    OPEN_TEXT_PAGE: 'Open Text Page',
    INTENT: 'Intent'
};

const ActionFieldsTooltips = {
    [itemActions.PAGELINK]: {
        pageId: 'Select or create the Page that should be opened by the Editorial item.'
    },
    [itemActions.DEEPLINK]: {
        url: 'Set the desired hyperlink to a specific location within the app or website.'
    },
    [itemActions.APPLINK]: {
        select_app: 'Select or create the Application that should be opened by the Editorial item.'
    },
    [itemActions.PLAYVIDEO]: {
        url: 'Select a video from the File Manager or set the URL of the video that should be started by the Editorial item.',
        url_superadminUI: 'Set the URL of the video that should be started by the Editorial item.'
    },
    [itemActions.PAGELINK_WITH_TEASER]: {
        pageId: 'Select or create the Page that should be opened by the Editorial item.',
        moduleId: 'Select the Module that should be displayed as a teaser for the Editorial item.'
    },
    [itemActions.INTENT]: {
        action: 'Define the general action that should be performed by the app.',
        uri: 'Specify the data to be operated on by the intent.',
        package: 'Set the specific component that should handle the intent.'
    },
    [itemActions.OPEN_POP_UP]: {
        title: 'Title of the pop-up that will be opened by the Editorial item.',
        subtitle: 'Subtitle of the pop-up that will be opened by the Editorial item.',
        url: 'The URL used to generate the QR code on the pop-up that will be opened by the Editorial item.'
    },
    [itemActions.OPEN_TEXT_PAGE]: {
        title: 'Title of the Text page that the Editorial Item will open',
        description: 'Description of the Text page that the Editorial Item will open',
        backgroundImage: 'Background image of the Text page that the Editorial Item will open'
    }
};

export const OTHER_APPLICATION_VALUE = 'Other';

const FIELDS_WITH_TRANSLATION = ['title', 'subtitle', 'description'];
const MULTILINE_FIELDS = ['description', 'subtitle'];
const ACTION_TYPES_WITH_TRANSLATIONS = [itemActions.OPEN_TEXT_PAGE, itemActions.OPEN_POP_UP];
const ActionDialog: FC<ActionDialogProps> = ({ open, action, name, isTemplate, onSave, onClose, inGalleryOrButton }) => {
    const [actionName, setActionName] = useState<string>(name || '');
    const [actionType, setActionType] = useState<string>('');
    const [actionOptions, setActionOptions] = useState<any[]>([]);
    const [actionFieldsValue, setActionFieldsValue] = useState<any>({});
    const [errors, setErrors] = useState<{ action?: string; [key: string]: any; selectedApplication?: string }>({});

    const [showNewPageDialog, setShowNewPageDialog] = useState<boolean>(false);
    const [newPageId, setNewPageId] = useState<string>('');

    const { contentActionTypes, deeplinkOptions, deeplinkOptionsLoading }: ItemState = useAppSelector((state) => state.items);
    const { loading: uploadingFile }: FilesState = useAppSelector((state) => state.files);
    const { applications }: ApplicationsState = useAppSelector((state) => state.applications);
    const { allPages: pages, loadingAllPages: pagesLoading }: pagesState = useAppSelector((state) => state.pages);
    const { allModules: modules, loadingAllModules: modulesLoading }: ModulesState = useAppSelector((state) => state.modules);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { config } = useAppSelector((state) => state.config);

    const [selectedApplication, setSelectedApplication] = useState('');
    const [optionsExpanded, setOptionsExpanded] = useState(false);
    const [selectedDeeplinkOption, setSelectedDeeplinkOption] = useState<any>(undefined);

    const [openFileManagerDialog, setOpenFileManagerDialog] = useState(false);
    const [fieldKeyForFileManagerCallback, setFieldKeyForFileManagerCallback] = useState<'url' | 'backgroundImage' | undefined>();

    const showActionNameWarning = inGalleryOrButton && !actionName;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { translate } = useTranslation();

    const fileInputRef = useRef<any>(null);

    const isSuperAdminUI = window.location.pathname.includes('superadmin');

    const loadPages = async (projectId: string) => {
        return await dispatch(fetchAllPages({ projectId })).unwrap();
    };

    const loadModules = async (projectId: string) => {
        // fetching all modules here, not only the ones for the current page
        return await dispatch(fetchAllModules({ projectId })).unwrap();
    };

    const loadApplications = async () => {
        return await dispatch(fetchApplications()).unwrap();
    };

    const loadContentActionTypes = async () => {
        return await dispatch(fetchContentActionTypes());
    };

    const loadDeeplinkOptions = async (projectId: string) => {
        return await dispatch(fetchDeeplinkOptions(projectId));
    };

    const handleFileClickCallback = (url: string) => {
        if (typeof fieldKeyForFileManagerCallback !== 'string') return;
        const fileExtension = _.last(url?.split('.')) || '';
        let acceptedExtensions: string[] = [];
        let newValue = '';
        if (fieldKeyForFileManagerCallback === 'url') {
            acceptedExtensions = videoExtensions;
            newValue = getVideoCdnUrl(config.videoCdnUrl, url);
        }
        if (fieldKeyForFileManagerCallback === 'backgroundImage') {
            acceptedExtensions = ['png', 'jpg'];
            newValue = getImgixUrl(config.imgixBaseUrl, url);
        }
        const isSupported = acceptedExtensions.includes(fileExtension);
        if (!isSupported) return notSupportedExtensionAlert(acceptedExtensions);
        setActionFieldsValue({ ...actionFieldsValue, [fieldKeyForFileManagerCallback]: newValue });
        setErrors(_.omit(errors, fieldKeyForFileManagerCallback));
        setOpenFileManagerDialog(false);
        setFieldKeyForFileManagerCallback(undefined);
    };

    const handleCloseClick = () => {
        onClose && onClose();
        setActionName('');
        setActionType('');
        setActionFieldsValue({});
        setSelectedApplication('');
        setSelectedDeeplinkOption(undefined);
        setOptionsExpanded(false);
        setErrors({});
    };

    const handleSaveClick = async () => {
        if (!validateAction()) return;
        const action = { type: actionType, ...actionFieldsValue };
        onSave && onSave(actionName, action);
        handleCloseClick();
    };

    const validateAction = () => {
        const newErrors = { ...errors };

        if (actionType !== itemActions.NOACTION && actionType !== itemActions.APPLINK) {
            Object.entries(actionFieldsValue).forEach(([key, value]) => {
                newErrors[key] = validator({ required: true }, value as string);
            });
        }
        if (actionType === itemActions.APPLINK) {
            let valuesCount = Object.values(actionFieldsValue).filter((value: any) => !!value).length;
            Object.keys(actionFieldsValue).forEach((key: string) => {
                newErrors[key] = valuesCount === 0 ? 'At least one field needs to be filled' : '';
            });

            if (valuesCount === 0) {
                newErrors['selectedApplication'] = validator({ required: true }, selectedApplication);
            }
        }
        if (actionType === itemActions.DEEPLINK) {
            newErrors['url'] = validateDeeplinkUrl(actionFieldsValue.url);
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const createNewPage = async (page: Page) => {
        const response = await dispatch(createPage({ page })).unwrap();
        if (response.id) {
            setNewPageId(response.id);
            activeProjectId && loadPages(activeProjectId);
        }
    };

    const addButton: DialogButton = {
        label: 'Add',
        type: 'BLUE',
        onClick: handleSaveClick,
        disabled: pagesLoading,
        loading: uploadingFile
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const pageOptions = getPagesSelectOptions(pages, activeProjectId || '', navigate, translate);
    const pageValue = pageOptions?.find((page: any) => page.value === actionFieldsValue?.pageId);

    const moduleOptions = modules.map((module) => {
        return {
            label: (
                <DropdownOptionWrapper>
                    <TruncatedText>
                        <ObjectNameTooltip id={module._id} name={translate(module.name || EMPTY_WORD_STRING)} type={ObjectTypes.MODULES} />
                    </TruncatedText>
                    <TranslationTooltip translationKey={module.name} />
                    {renderPublishStatusIcon(module.publishStatus)}
                </DropdownOptionWrapper>
            ),
            value: module._id,
            valueForSearch: translate(module.name || EMPTY_WORD_STRING)
        };
    });
    const moduleValue = moduleOptions?.find((module: any) => module.value === actionFieldsValue?.moduleId);

    useEffect(() => {
        if (!open) return;

        // LOAD RESOURCES
        if (activeProjectId) {
            loadDeeplinkOptions(activeProjectId);
            // IMPORTANT! LOAD THEM IF THERE'S AN ID PRESENT, OTHERWISE IT WILL START LOADING ALL EXISTING MODULES/PAGES
            loadPages(activeProjectId);
            loadModules(activeProjectId);
        }
        // do not reload project independent resources unless they do not already exist in slices
        !contentActionTypes?.length && loadContentActionTypes();
        !Object.keys(applications || {}).length && loadApplications();

        // SET ACTION OPTIONS
        if (!contentActionTypes) return;
        setActionOptions(() => {
            return contentActionTypes
                .filter((opt) => (isTemplate ? ![itemActions.PAGELINK, itemActions.PAGELINK_WITH_TEASER].includes(opt.key) : opt.key))
                .map((option) => {
                    return {
                        value: option.key,
                        label: _.get(ActionTypeLabels, option.key, 'No Action')
                    };
                });
        });

        // SET INITIAL VALUES
        if (action) {
            const { type, ...fields } = action;
            setActionType(type);
            if (type === itemActions.APPLINK) {
                setSelectedApplication(checkAppExistsAndReturnKey(fields));
            }
            setActionFieldsValue(fields);
        } else {
            setActionType(itemActions.NOACTION);
        }
        setActionName(name || '');
    }, [open, contentActionTypes]);

    useEffect(() => {
        if (!open || !action) return;
        if (action.type === itemActions.DEEPLINK && deeplinkOptions?.length) {
            setSelectedDeeplinkOption(calculateSelectedDeeplinkOption(action.url));
        }
    }, [open, deeplinkOptions]);

    useEffect(() => {
        if (newPageId) {
            setActionFieldsValue({ ...actionFieldsValue, pageId: newPageId });
        }
    }, [newPageId]);

    const checkAppExistsAndReturnKey = (values: ApplicationValues) => {
        for (const appKey in applications) {
            const isSelected = Object.keys(applications[appKey]).every(
                (key) => applications[appKey][key as keyof ApplicationValues] === `${values[key as keyof ApplicationValues]}`.trim()
            );
            if (isSelected) {
                return appKey;
            }
        }
        return '';
    };

    const calculateSelectedDeeplinkOption = (url: string) => {
        if (!url) return undefined;

        const countMatchingIndices = (arr1: string[], arr2: string[]) =>
            arr1.reduce((count, val, i) => count + (val === arr2[i] ? 1 : 0), 0);

        return deeplinkOptions?.find((option) => {
            const optionValue = option.value;
            if (optionValue === url) return true; // for when the url does not contain any editable parts

            const optionParts = optionValue.split('/');
            const matching = countMatchingIndices(url.split('/'), optionParts);
            if (matching < optionParts.length - 3) {
                // only options with max 3 unmatching parts are considered
                return false;
            }

            const editableIndex = optionValue.indexOf('[');
            const fixedValue = optionValue.substring(0, editableIndex);
            const optionSections = (optionValue.match(/\[.*?\]/g) || []).length; // count the number of [] sections in the deeplink option
            const urlSections = url.replace(fixedValue, '').split('/').length; // count the number of [] sections in the url, which should be equal to the / parts after removing the fixed part
            return optionSections === urlSections;
        });
    };

    const validateDeeplinkUrl = (url: string) => {
        if (!selectedDeeplinkOption) return validator({ required: true }, url); // if no deeplink option is selected, the URL is a free input
        const sectionCount = (selectedDeeplinkOption.value.match(/\[.*?\]/g) || []).length; // count the number of [] sections in the deeplink option
        if (sectionCount === 0) return '';

        const editableIndex = selectedDeeplinkOption.value.indexOf('[');

        const regex = new RegExp(`^([\\w%-]+)(/[\\w%-]+){${sectionCount - 1}}$`); // create a regex that matches the URL structure taking the number of [] sections into account
        return regex.test(`${url}`.substring(editableIndex)) ? '' : 'Please match the selected option pattern!';
    };

    useEffect(() => {
        if (actionType !== itemActions.APPLINK) return;
        // the options should be expanded if the app fields are overidden
        if (!selectedApplication && Object.values(actionFieldsValue).some((val) => !!val)) {
            setOptionsExpanded(true);
        }
    }, [actionType]);

    const createFiles = async (files: File[]) => {
        const prefix = 'system_templates/items';
        const response = await dispatch(uploadFilesSync({ files, prefix, overwrite: true })).unwrap();
        return response.urls;
    };

    const handleOpenTextPageBackgroundImageBrowse = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //used for items templates
        const image = e.target?.files?.[0];
        if (!image) return;

        const url = (await createFiles([image]))[0];
        const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_CONFIG.container, url);
        const backgroundImage = getImgixUrl(config.imgixBaseUrl, fileName);
        setActionFieldsValue({ ...actionFieldsValue, backgroundImage: backgroundImage });

        //clear the input
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const renderActionFields = () => {
        const currentAction = contentActionTypes?.find((act) => act.key === actionType);
        if (!currentAction) return;

        const fields = currentAction.fields.map((field: any, index: number) => {
            const tooltipName = field.name as keyof typeof ActionFieldsTooltips[keyof typeof itemActions];
            switch (field.type) {
                case 'string':
                    if (!(field.name in actionFieldsValue)) _.assign(actionFieldsValue, { [field.name]: '' });
                    if (actionType === itemActions.PAGELINK)
                        return (
                            <DialogDropdownSingle
                                key={`param_${index}_${field.name}`}
                                toolTipText={ActionFieldsTooltips[actionType][tooltipName]}
                                options={pageOptions}
                                value={pageValue}
                                withTopMargin
                                labelText={'Select Page'}
                                placeholder={'Select Page'}
                                onChange={(value: any) => {
                                    setActionFieldsValue({ ...actionFieldsValue, pageId: value.value });
                                    setErrors(_.omit(errors, 'pageId'));
                                }}
                                error={errors['pageId']}
                                newOption={{
                                    name: 'Page',
                                    onClick: () => {
                                        setShowNewPageDialog(true);
                                    }
                                }}
                                isDisabled={pagesLoading}
                            />
                        );
                    if (actionType === itemActions.PAGELINK_WITH_TEASER)
                        return (
                            <>
                                <DialogDropdownSingle
                                    key={`param_${index}_${field.name}`}
                                    options={pageOptions}
                                    value={pageValue}
                                    withTopMargin
                                    labelText={'Select Page'}
                                    placeholder={'Select Page'}
                                    toolTipText={ActionFieldsTooltips[actionType][tooltipName]}
                                    onChange={(value: any) => {
                                        setActionFieldsValue({ ...actionFieldsValue, pageId: value.value });
                                        setErrors(_.omit(errors, 'pageId'));
                                    }}
                                    error={errors['pageId']}
                                    newOption={{
                                        name: 'Page',
                                        onClick: () => {
                                            setShowNewPageDialog(true);
                                        }
                                    }}
                                    isDisabled={pagesLoading}
                                />
                                <DialogDropdownSingle
                                    key={`param_${index}_${field.name}`}
                                    options={moduleOptions}
                                    value={moduleValue}
                                    withTopMargin
                                    labelText={'Select Module'}
                                    placeholder={'Select Module'}
                                    toolTipText={ActionFieldsTooltips[actionType]['moduleId']}
                                    onChange={(value: any) => {
                                        setActionFieldsValue({ ...actionFieldsValue, moduleId: value.value });
                                        setErrors(_.omit(errors, 'moduleId'));
                                    }}
                                    error={errors['moduleId']}
                                    isDisabled={modulesLoading}
                                />
                            </>
                        );

                    if (actionType === itemActions.APPLINK) {
                        const fieldName: keyof ApplicationFieldTooltips = field.name;

                        return optionsExpanded ? (
                            <DialogTextField
                                key={`param_${index}_${field.name}`}
                                value={actionFieldsValue[field.name] || ''}
                                onChange={(e: any) => {
                                    const newValues = { ...actionFieldsValue, [field.name]: e.target.value };
                                    setSelectedApplication(checkAppExistsAndReturnKey(newValues));
                                    setActionFieldsValue(newValues);
                                    setErrors(
                                        _.omit(errors, [...currentAction.fields.map((field: any) => field.name), 'selectedApplication'])
                                    );
                                }}
                                toolTipText={ApplicationFieldTooltipsValues[fieldName]}
                                label={ApplicationFieldLabelValues[fieldName]}
                                error={errors[field.name]}
                                isUrl
                                withTopMargin
                            />
                        ) : null;
                    }

                    if (actionType === itemActions.PLAYVIDEO) {
                        const fileManagerAdornment = (
                            <InputAdornment position="end">
                                <FileManagerAdornmentWrapper
                                    onClick={() => {
                                        setFieldKeyForFileManagerCallback('url');
                                        setOpenFileManagerDialog(true);
                                    }}
                                >
                                    <SVGInline src={icons.fileManagerIcon} />
                                </FileManagerAdornmentWrapper>
                            </InputAdornment>
                        );
                        return (
                            <DialogTextField
                                key={`param_${index}_${field.name}`}
                                value={actionFieldsValue[field.name] || ''}
                                onChange={(e: any) => {
                                    setActionFieldsValue({ ...actionFieldsValue, [field.name]: e.target.value });
                                    setErrors(
                                        _.omit(
                                            errors,
                                            currentAction.fields.map((field: any) => field.name)
                                        )
                                    );
                                }}
                                error={errors[field.name]}
                                withTopMargin
                                toolTipText={ActionFieldsTooltips[actionType][isSuperAdminUI ? 'url_superadminUI' : 'url']}
                                placeholder={isSuperAdminUI ? 'Type URL' : 'Browse Files or type URL'}
                                InputProps={!isSuperAdminUI && { endAdornment: fileManagerAdornment }}
                                label={(field.name === 'url' || field.name === 'uri'
                                    ? field.name.toUpperCase()
                                    : _.capitalize(field.name)
                                ).replaceAll('_', ' ')}
                                isUrl
                            />
                        );
                    }

                    if (actionType === itemActions.OPEN_TEXT_PAGE && field.name === 'backgroundImage') {
                        const fileManagerAdornment = (
                            <InputAdornment position="end">
                                <FileManagerAdornmentWrapper
                                    onClick={() => {
                                        if (isSuperAdminUI) {
                                            fileInputRef?.current?.click();
                                            return;
                                        }

                                        setFieldKeyForFileManagerCallback('backgroundImage');
                                        setOpenFileManagerDialog(true);
                                    }}
                                >
                                    <SVGInline src={icons.fileManagerIcon} />
                                </FileManagerAdornmentWrapper>
                            </InputAdornment>
                        );
                        return (
                            <DialogTextField
                                key={`param_${index}_${field.name}`}
                                value={actionFieldsValue[field.name] || ''}
                                toolTipText={ActionFieldsTooltips[actionType][tooltipName]}
                                onChange={(e: any) => {
                                    setActionFieldsValue({ ...actionFieldsValue, [field.name]: e.target.value });
                                    setErrors(
                                        _.omit(
                                            errors,
                                            currentAction.fields.map((field: any) => field.name)
                                        )
                                    );
                                }}
                                error={errors[field.name]}
                                placeholder={'Browse Files or type URL'}
                                InputProps={{ endAdornment: fileManagerAdornment }}
                                label={'Background Image'}
                                withTopMargin
                                helperText={'*Supported files: PNG, JPG'}
                                isUrl
                            />
                        );
                    }

                    if (actionType === itemActions.DEEPLINK && selectedDeeplinkOption) {
                        const fullUrlValue: string = selectedDeeplinkOption.value;
                        const editableIndex = fullUrlValue.indexOf('[');
                        const hasEditableValue = editableIndex >= 0;

                        const fixedValue = hasEditableValue ? fullUrlValue.substring(0, editableIndex) : fullUrlValue;
                        const placeholderValue = hasEditableValue ? fullUrlValue.substring(editableIndex) : '';
                        const editableValue = hasEditableValue ? actionFieldsValue?.url?.substring(editableIndex) : '';

                        const deelpinkTooltipKey = selectedDeeplinkOption.tooltipKey
                            ? `item_deeplink_${selectedDeeplinkOption.tooltipKey}`
                            : '';

                        return (
                            <DeeplinkUrlContainer $width1={0}>
                                <DialogTextField
                                    key={`param_${index}_fixed_url`}
                                    value={fixedValue}
                                    onChange={(e: any) => {}}
                                    withTopMargin
                                    label={'URL (fixed)'}
                                    toolTipText={
                                        hasEditableValue
                                            ? 'This part of the URL is fixed and cannot be changed'
                                            : (tooltips as any)[deelpinkTooltipKey]
                                    }
                                    isDisabled
                                />

                                {hasEditableValue && (
                                    <DialogTextField
                                        key={`param_${index}_url`}
                                        value={editableValue}
                                        onChange={(e: any) => {
                                            const newUrl = `${fixedValue}${e.target.value}`;
                                            setActionFieldsValue({ ...actionFieldsValue, url: newUrl });
                                            setErrors(_.omit(errors, 'url'));
                                        }}
                                        placeholder={placeholderValue}
                                        error={errors.url}
                                        toolTipText={
                                            deelpinkTooltipKey
                                                ? (tooltips as any)[deelpinkTooltipKey]
                                                : ActionFieldsTooltips[actionType][tooltipName]
                                        }
                                        withTopMargin
                                        label={'URL'}
                                        isUrl
                                    />
                                )}
                            </DeeplinkUrlContainer>
                        );
                    }

                    const withTranslation =
                        ACTION_TYPES_WITH_TRANSLATIONS.includes(actionType) && FIELDS_WITH_TRANSLATION.includes(field.name);

                    const isMultiLine =
                        (actionType === itemActions.OPEN_TEXT_PAGE && MULTILINE_FIELDS.includes(field.name)) ||
                        (actionType === itemActions.OPEN_POP_UP && MULTILINE_FIELDS.includes(field.name));

                    if (isMultiLine) {
                        return (
                            <MultiLineTextField
                                key={`param_${index}_${field.name}`}
                                value={actionFieldsValue[field.name] || ''}
                                onChange={(e: any) => {
                                    setActionFieldsValue({ ...actionFieldsValue, [field.name]: e.target.value });

                                    setErrors(_.omit(errors, field.name));
                                }}
                                error={errors[field.name]}
                                toolTipText={ActionFieldsTooltips[actionType][tooltipName]}
                                withTranslations={!isSuperAdminUI && withTranslation}
                                label={(field.name === 'url' || field.name === 'uri'
                                    ? field.name.toUpperCase()
                                    : _.capitalize(field.name)
                                ).replaceAll('_', ' ')}
                                withTopMargin
                                isDescription
                            />
                        );
                    }

                    return (
                        <DialogTextField
                            key={`param_${index}_${field.name}`}
                            value={actionFieldsValue[field.name] || ''}
                            onChange={(e: any) => {
                                setActionFieldsValue({ ...actionFieldsValue, [field.name]: e.target.value });

                                setErrors(_.omit(errors, field.name));
                            }}
                            error={errors[field.name]}
                            toolTipText={ActionFieldsTooltips[actionType][tooltipName]}
                            withTopMargin
                            withTranslations={!isSuperAdminUI && withTranslation}
                            label={(field.name === 'url' || field.name === 'uri'
                                ? field.name.toUpperCase()
                                : _.capitalize(field.name)
                            ).replaceAll('_', ' ')}
                            isUrl={field.name === 'url' || field.name === 'uri'}
                        />
                    );
                default:
                    break;
            }
        });

        if (actionType === itemActions.APPLINK) {
            fields.unshift(
                <ActionFieldsToggle onClick={() => setOptionsExpanded(!optionsExpanded)} key={'expand_toggle'}>
                    {`${!optionsExpanded ? 'Show' : 'Hide'} Fields`}{' '}
                    <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </ActionFieldsToggle>
            );

            fields.unshift(
                <ApplicationSelect
                    labelText={'Select Application'}
                    toolTipText={ActionFieldsTooltips[actionType]['select_app']}
                    selectedApplication={selectedApplication}
                    onSelectedApplicationChange={setSelectedApplication}
                    errors={errors}
                    setErrors={setErrors}
                    onApplicationFieldValuesChange={(newApplicationValues?: ApplicationValues) =>
                        setActionFieldsValue(newApplicationValues || {})
                    }
                />
            );
        }

        if (actionType === itemActions.DEEPLINK) {
            fields.unshift(
                <DialogDropdownSingle
                    key={`param_deeplink_options`}
                    options={isTemplate ? [] : deeplinkOptions || []}
                    value={selectedDeeplinkOption}
                    labelText={'Deeplinking options'}
                    placeholder={'Select an option'}
                    toolTipText={'An option can be selected from here to prefill the deeplink URL schema'}
                    onChange={(value: any) => {
                        const optionValue = value.value;
                        const editableIndex = optionValue.indexOf('[');
                        setErrors(_.omit(errors, 'url'));
                        setSelectedDeeplinkOption(optionValue ? value : undefined);
                        setActionFieldsValue({ ...actionFieldsValue, url: editableIndex > 0 ? '' : optionValue });
                    }}
                    isDisabled={deeplinkOptionsLoading}
                    withTopMargin
                    optional
                    clearable
                />
            );
        }

        return fields;
    };

    if (!open) {
        return null;
    }

    return (
        <GenericDialog
            type={DialogTypes.Form}
            actionButtons={[cancelButton, addButton]}
            title={'Set Editorial Action'}
            onClose={handleCloseClick}
            warnings={
                showActionNameWarning
                    ? [{ icon: icons.warningIcon, critical: true, onClick: () => actionNameMissingAlert(false, false) }]
                    : undefined
            }
        >
            <DialogTextField
                value={actionName}
                onChange={(e: any) => {
                    setActionName(e.target.value);
                    setErrors(_.omit(errors, 'actionName'));
                }}
                label={'Action Name'}
                toolTipText={`This field is only relevant for items in modules with templates: 'Gallery' and 'Button'. Please set an actionName for the item that will be added to these templates.`}
                optional
                withTranslations={!isTemplate}
            />
            <DialogDropdownSingle
                withTopMargin
                labelText={'Action'}
                options={actionOptions}
                value={actionOptions.find((option) => option.value === actionType)}
                toolTipText={'Select the action that should be performed by the Editorial item.'}
                placeholder={'Editorial Action'}
                onChange={(value: any) => {
                    setActionType(value.value);
                    setActionFieldsValue({});
                    setErrors({});
                    setSelectedApplication('');
                    setSelectedDeeplinkOption(undefined);
                }}
            />
            {renderActionFields()}

            <NewPageDialog
                open={showNewPageDialog}
                onClose={() => setShowNewPageDialog(false)}
                onSave={(page) => {
                    createNewPage(page);
                }}
            />
            <FileManagerDialog
                open={openFileManagerDialog}
                onClose={() => setOpenFileManagerDialog(false)}
                onFileClick={handleFileClickCallback}
            />
            <input
                id="file-upload-input"
                type="file"
                accept={imgExtensions.join(',')}
                onChange={(e) => {
                    handleOpenTextPageBackgroundImageBrowse(e);
                }}
                ref={fileInputRef}
                hidden
            />
        </GenericDialog>
    );
};

export default ActionDialog;
