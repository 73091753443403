import styled from 'styled-components';
import { UserAvatar } from '../../Projects/Projects.css';
import getTheme, { fontSizesCSS } from '../../../style/themes/theme';
import { MoreInfoContentHolder } from './MoreInfoDialog.css';

const theme: any = getTheme();

export const ActivityDialogObjectName = styled.div`
    ${fontSizesCSS.Body2};
`;

export const ActivityDialogInfo = styled.div`
    ${fontSizesCSS.Caption1};
`;

export const ActivitiesWrapper = styled(MoreInfoContentHolder)`
    gap: 8px;
    display: flex;
    margin-top: 36px;
    flex-direction: column;
`;

export const ActivityContainer = styled.div`
    display: flex;
    position: relative;
    border-radius: 3px;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${theme.palette.background.border};

    ${UserAvatar} {
        width: 32px;
        height: 32px;
        margin-left: auto;
    }
`;

export const ActivityInfo = styled.div<{ $withArrow?: boolean; $open?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${(props) => (props.$open ? '12px 44px 0 16px' : '12px 44px 12px 16px')};
    box-sizing: border-box;
    ${(props) => props.$withArrow && 'cursor: pointer;'}
`;

export const UserActivityInfo = styled(ActivityInfo)`
    height: 56px;
    padding: 12px 44px 12px 16px;
`;

export const ActivityType = styled.div`
    ${fontSizesCSS.Body2};
`;

export const ActivityDate = styled.div`
    margin-left: 16px;
    ${fontSizesCSS.Body1};
`;

export const ArrowIcon = styled.div`
    right: 16px;
    height: 14px;
    position: absolute;
    svg {
        opacity: 1;
        width: 14px;
        height: 14px;
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ActivityDetails = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 2px;
    flex-direction: column;
    padding: 0 44px 0 16px;
    box-sizing: border-box;

    &:last-child {
        padding-bottom: 14px;
    }
`;

export const ActivityField = styled.div`
    display: flex;
    margin-top: 14px;
    padding-top: 14px;
    align-items: center;
    ${fontSizesCSS.Caption2};
    justify-content: space-between;
    border-top: 1px solid ${theme.palette.background.border};
`;

export const ActivityFieldWrapper = styled.div`
    ${fontSizesCSS.Caption2};
    border-top: 1px solid ${theme.palette.background.border};
    margin-top: 14px;
`;

export const ActivitySubField = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 14px;
`;

export const ActivityFieldKey = styled.div`
    display: flex;
    gap: 8px;
    max-width: 70%;
`;

export const UserActivityFieldTitle = styled(ActivityFieldKey)`
    ${fontSizesCSS.Body2};
    max-width: 40%;
    width: 100%;
    align-items: center;
    padding-right: 24px;

    span {
        line-height: 0;
    }
`;

export const UserActivityDetails = styled.div`
    display: flex;
    gap: 24px;
`;

export const UserActivityField = styled.div``;

export const ObjectTypeIcon = styled.div`
    display: flex;
    align-items: center;
    svg {
        height: 18px;
        width: 18px;
    }
`;

export const ActivityFieldStatus = styled.div`
    margin-left: auto;
    ${fontSizesCSS.Caption1};
`;

export const ChildWrapper = styled.div``;

export const ChildrenContainer = styled.div`
    margin-left: 10px;

    ${ChildWrapper} {
        position: relative;
        padding-left: 28px;
        border-left: 1px solid #cccccc;
        &:last-child {
            border-color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -1px;
            width: 16px;
            height: 24px;
            border: solid #cccccc;
            border-width: 0 0 1px 1px;
        }
    }
`;
