import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import {
    contentSourceTypes as sourceTypes,
    contentWorldToolTips,
    ContentWorldTypes,
    Item,
    itemTypes as itemTypes,
    RealDynamicContentSourceTypes
} from '../../../types/Item';
import GenericDialog, {
    DialogButton,
    DialogCheckbox,
    DialogDropdownSingle,
    DialogRadioButton,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';

import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import {
    OptionEntry,
    OptionSection,
    OptionsToggle,
    OptionsContainer,
    RadioButtonsWrapper,
    TargetConditionsInfo,
    TargetConditionsInfoWrapper,
    TargetConditionsWrapper,
    ToggleExtraFieldContainer
} from '../../Modules/Dialogs/NewModule.css';
import { validator } from '../../../utils/fnValidator';
import { ItemState } from '../../../redux/slices/itemSlice';
import { Module, templates } from '../../../types/Module';
import { ContentSourceLabelContainer, ContentWorldExtraFields, ContentWorldField } from '../Items.css';
import { actionNameMissingAlert, DIALOG_NAMES, dialogAlert, ToastAlert } from '../../../utils/fnDialogs';
import { createSource, fetchSources, SourcesState } from '../../../redux/slices/sourceSlice';
import { renderTooltip, renderTooltipWithKey, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import { DynamicSource } from '../../../types/DynamicSource';
import { DisplayConditionsState } from '../../../redux/slices/displayConditionsSlice';
import ObjectNameTooltip from '../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { ObjectTypes } from '../../../types/Object';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { ModulesState } from '../../../redux/slices/moduleSlice';
import { pagesState } from '../../../redux/slices/pagesSlice';
import { InputLabelWithIconWrapper } from '../../common/Dialog/GenericDialog.css';
import { TargetConditionsTypes } from '../../Modules/Dialogs/NewModule';
import Labels from '../../common/Labels/Labels';
import { MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import { NewSourceDialog } from '../../Sources/Dialogs/NewSource';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { audiencesState } from '../../../redux/slices/audienceSlice';
import { ABTestingGroupSelect } from '../../common/Select/ABTestingGroupSelect';
import { ConfigurableSelect, ConfigurableKeys } from '../../TargetConditions/DisplayConditions/ConfigurableSelect';

type newItemProps = {
    open: boolean;
    item?: Item;
    module?: Module;
    duplicate?: boolean;
    onClose?: () => void;
    onSave?: (arg: Item, removeAutoCollection?: boolean, removeIncludeProviderCardLogo?: boolean) => Promise<void>;
    templateType?: string;
    collectionType?: itemTypes;
    isCollection?: boolean;
    includeProviderCardLogoProviderName?: string;
    isContentWorldModule?: boolean;
    loading?: boolean;
};

export const NewItem: FC<newItemProps> = ({
    open,
    item,
    module,
    duplicate,
    collectionType,
    includeProviderCardLogoProviderName,
    isCollection,
    isContentWorldModule,
    onClose,
    onSave,
    loading
}) => {
    const [name, setName] = useState<string>('');
    const [itemType, setItemType] = useState<itemTypes | undefined>(undefined);
    const [contentSource, setContentSource] = useState<any>(null);
    const [contentSourceOptions, setContentSourceOptions] = useState<any[]>([]);

    const [dynamicSourceOptions, setDynamicSourceOptions] = useState<any[]>([]);
    const [dynamicSourceId, setDynamicSourceId] = useState<string>('');

    const [contentSourceType, setContentSourceType] = useState<sourceTypes | undefined>(undefined);
    const [contentSourceTypeOptions, setContentSourceTypeOptions] = useState<any[]>([]);
    const [abTestingGroupIds, setAbTestingGroupIds] = useState<any[]>([]);
    const [targets, setTargets] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [maxNumberOfItems, setMaxNumberOfItems] = useState<string>('');
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [videoAutoplay, setVideoAutoplay] = useState<boolean>(false);
    const [alterateBehaviour, setAlterateBehaviour] = useState<boolean>(false);
    const [alterateBehaviourLabel, setAlterateBehaviourLabel] = useState('');
    const [singleAsset, setSingleAsset] = useState<boolean>(false);
    const [singleAssetId, setSingleAssetId] = useState<string>('');
    const [contentWorldType, setContentWorldType] = useState<string>('');
    const [showContentWorld, setShowContentWorld] = useState<boolean>(false);
    const [targetConditionsType, setTargetConditionsType] = useState<TargetConditionsTypes>(TargetConditionsTypes.PARENT);
    const [errors, setErrors] = useState<{
        contentSource?: string;
        contentSourceType?: string;
        singleAssetId?: string;
        targets?: string;
        alterateBehaviourLabel?: string;
    }>({});

    const [openCreateNew, setOpenCreateNew] = useState(false);
    const [newSourceId, setNewSourceId] = useState('');

    const { audiences }: audiencesState = useAppSelector((state) => state.audiences);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { contentSourceTypes, createOrModifyLoading }: ItemState = useAppSelector((state) => state.items);
    const { createOrModifyLoading: modulesLoading }: ModulesState = useAppSelector((state) => state.modules);
    const { selectedPageLoading: pageLoading }: pagesState = useAppSelector((state) => state.pages);
    const { sources, loading: sourcesLoading }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { loading: conditionsLoading }: DisplayConditionsState = useAppSelector((state) => state.displayConditions);
    const { selectedPage } = useAppSelector((state) => state.pages);

    const dispatch = useDispatch();

    const handleCloseClick = () => {
        onClose && onClose();
        setName('');
        setContentSource('');
        setContentSourceType(undefined);
        setItemType(undefined);
        setTargets([]);
        setAbTestingGroupIds([]);
        setMaxNumberOfItems('');
        setIsOpen(false);
        setErrors({});
        setDynamicSourceId('');
        setNewSourceId('');
        setSingleAsset(false);
        setSingleAssetId('');
        setContentWorldType('');
        setShowContentWorld(false);
        setVideoAutoplay(false);
        setTargetConditionsType(TargetConditionsTypes.PARENT);
        setAlterateBehaviour(false);
        setAlterateBehaviourLabel('');
    };

    const moduleItemsNumber = module?.items?.length || 0;
    const shouldRenderAutoCollectionAlert =
        (moduleItemsNumber > (item ? 1 : 0) && module?.autoCollection) ||
        (moduleItemsNumber === 0 && module?.autoCollection && itemType === itemTypes.EDITORIAL);

    const shouldRenderIncludeProviderCardLogoAlert =
        !!includeProviderCardLogoProviderName &&
        itemType === itemTypes.DYNAMIC &&
        contentSourceType !== includeProviderCardLogoProviderName;

    const handleSaveClick = () => {
        if (!validateItem()) return;
        if (!itemType) return;

        if ((collectionType && itemType !== collectionType) || (isContentWorldModule && itemType === itemTypes.DYNAMIC)) return;

        if (shouldRenderAutoCollectionAlert) {
            ToastAlert(
                'critical_warning',
                '',
                '',
                undefined,
                DIALOG_NAMES.DYNAMIC_ITEM_AUTOCOLLECTION,
                () => {
                    renderAutoCollectionAlertDialog();
                },
                shouldRenderIncludeProviderCardLogoAlert
                    ? () => {
                          ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                              renderIncludeProviderCardLogoAlertDialog();
                          });
                      }
                    : undefined
            );
        }
        if (shouldRenderIncludeProviderCardLogoAlert && !shouldRenderAutoCollectionAlert) {
            ToastAlert('warning', '', '', undefined, DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, () => {
                renderIncludeProviderCardLogoAlertDialog();
            });
        }

        const newItem: Item = {
            itemType: itemType,
            _id: duplicate ? '' : item?._id || '',
            name: name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            conditionIds: targetConditionsType === TargetConditionsTypes.PARENT ? [] : targets,
            abTestingGroupIds: abTestingGroupIds || [],
            lastModified: item?.lastModified,
            contentWorldType,
            videoAutoplay,

            // not editorial
            ...(itemType !== itemTypes.EDITORIAL && {
                contentSourceType: contentSourceType,
                contentSource: contentSource,
                maxNumberOfItems: maxNumberOfItems ? parseInt(maxNumberOfItems) : 0,
                alterateBehaviour,
                alterateBehaviourLabel
            }),
            ...(duplicate &&
                itemType === itemTypes.EDITORIAL && {
                    title: item?.title,
                    subtitle: item?.subtitle,
                    description: item?.description,
                    backgroundImage: item?.backgroundImage,
                    backgroundColor: item?.backgroundColor,
                    heroBackgroundColor: item?.heroBackgroundColor,
                    heroBackgroundImage: item?.heroBackgroundImage,
                    videoForAutoplay: item?.videoForAutoplay,
                    logo: item?.logo,
                    action: item?.action,
                    actionName: item?.actionName
                })
        };
        if (itemType === itemTypes.DYNAMIC && RealDynamicContentSourceTypes.includes(contentSourceType!)) {
            newItem.dynamicSourceId = dynamicSourceId;
        } else {
            if (item?.dynamicSourceId) newItem.dynamicSourceId = '';
        }

        if (itemType === itemTypes.DYNAMIC && singleAsset) {
            newItem.singleAsset = singleAssetId;
        }
        if (item?.singleAsset && !singleAsset) {
            newItem.singleAsset = '';
        }
        onSave?.(newItem, shouldRenderAutoCollectionAlert, shouldRenderIncludeProviderCardLogoAlert).then(() => {
            if (
                module &&
                [templates.GALLERY, templates.BUTTON].includes(module.template) &&
                newItem.itemType === itemTypes.EDITORIAL &&
                !newItem.actionName
            ) {
                actionNameMissingAlert();
            }
        });
        handleCloseClick();
    };

    const saveButton: DialogButton = {
        label: !item ? 'Create' : 'Save',
        type: 'BLUE',
        onClick: () => handleSaveClick(),
        disabled: sourcesLoading || conditionsLoading || createOrModifyLoading || modulesLoading || loading || pageLoading,
        loading: createOrModifyLoading || modulesLoading || loading || pageLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    const validateItem = () => {
        const newErrors = { ...errors };

        newErrors.contentSourceType = validator(
            { required: true },
            `${contentSourceType !== undefined ? contentSourceType : itemType === itemTypes.EDITORIAL ? '-1' : ''}`
        );

        if (alterateBehaviour) {
            newErrors.alterateBehaviourLabel = validator({ required: true }, alterateBehaviourLabel);
        }

        if (contentSourceType && itemType !== itemTypes.EDITORIAL && !RealDynamicContentSourceTypes.includes(contentSourceType)) {
            newErrors.contentSource = validator({ required: true }, contentSource);
        }

        if (singleAsset) {
            newErrors.singleAssetId = validator({ required: true }, singleAssetId);
        }

        const hasAudiences = targets ? targets.some((target) => audiences.find((audience) => audience._id === target)) : false;
        if (targetConditionsType === TargetConditionsTypes.MANUAL && !hasAudiences) {
            newErrors.targets = 'Please select at least one Audience, or use the Parent’s conditions.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    const loadSources = async () => {
        return await dispatch(fetchSources({ addPermissions: false, projectId: activeProjectId })).unwrap();
    };

    const createDynamicSource = async (source: DynamicSource) => {
        const response = await dispatch(createSource(source)).unwrap();
        if (response.id) {
            setNewSourceId(response.id);
            loadSources();
        }
    };

    useEffect(() => {
        //we exclude EDITORIAL from the dropdown in case of  EDITING, if the source is DYNAMIC, or when the module is a COLLECTION
        const contentSourceTypeOptions =
            (collectionType && collectionType === itemTypes.DYNAMIC) || (item && item.itemType === itemTypes.DYNAMIC)
                ? []
                : [
                      {
                          value: 'editorial',
                          label: (
                              <ContentSourceLabelContainer>
                                  <SVGInline src={icons.editorialIcon} />
                                  <span>Editorial</span>
                              </ContentSourceLabelContainer>
                          ),
                          valueForSearch: 'Editorial'
                      }
                  ];
        if (contentSourceTypes?.length && collectionType !== itemTypes.EDITORIAL && !isContentWorldModule) {
            contentSourceTypeOptions.push(
                ...contentSourceTypes.map((source) => ({
                    value: source.value,
                    label: (
                        <ContentSourceLabelContainer>
                            <SVGInline src={icons.dynamicIcon} />
                            <span>{source.title}</span>
                        </ContentSourceLabelContainer>
                    ),
                    valueForSearch: source.title
                }))
            );
        }
        setContentSourceTypeOptions(contentSourceTypeOptions);
    }, [contentSourceTypes, item, collectionType, isContentWorldModule]);

    useEffect(() => {
        if (!itemType || itemType === itemTypes.EDITORIAL) return;

        loadSources();
    }, [itemType]);

    useEffect(() => {
        if (!sources.length) return setDynamicSourceOptions([]);

        if (!RealDynamicContentSourceTypes.includes(contentSourceType!)) return;

        const sourcesToShow = sources.filter((source) => source.service === contentSourceType);

        setDynamicSourceOptions(
            sourcesToShow.map((source) => {
                return {
                    value: source._id,
                    valueForSearch: source.name,
                    label: <ObjectNameTooltip id={source._id} name={source.name} type={ObjectTypes.DYNAMIC_SOURCES} />
                };
            })
        );
        if (newSourceId && sourcesToShow.find((source) => source._id === newSourceId)) {
            setDynamicSourceId(newSourceId);
        }
    }, [sources, contentSourceType]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (!open) return;

        // we need to check if there is an item to not overwrite the module's conditions when creating a new item form a module
        if (item) setTargets(item?.conditionIds || []);
        setName(item?.name || '');
        setItemType(item?.itemType);
        setContentSource(item?.contentSource || null);
        const sourceType = contentSourceTypes?.find((sourceType) => sourceType.value === item?.contentSourceType)?.value || undefined;
        setContentSourceType(sourceType);
        setMaxNumberOfItems(`${item?.maxNumberOfItems || ''}`);
        setDynamicSourceId(item?.dynamicSourceId || '');
        setAlterateBehaviour(!!item?.alterateBehaviour);
        setAlterateBehaviourLabel(item?.alterateBehaviourLabel || '');
        setVideoAutoplay(!!item?.videoAutoplay);
        setAbTestingGroupIds(item?.abTestingGroupIds || []);
        setSingleAsset(!!item?.singleAsset);
        setSingleAssetId(item?.singleAsset || '');
        setTargetConditionsType(!!item?.conditionIds?.length ? TargetConditionsTypes.MANUAL : TargetConditionsTypes.PARENT);
        setShowOptions(
            !!item?.singleAsset ||
                !!item?.alterateBehaviour ||
                !!item?.contentWorldType ||
                !!item?.videoAutoplay ||
                !!item?.alterateBehaviour
        );
        setContentWorldType(item?.contentWorldType || '');
        setShowContentWorld(!!item?.contentWorldType);
    }, [open, item, contentSourceTypes]);

    if (!isOpen) return null;

    // When EDITING, changing the source is allowed only if it was DYNAMIC earlier
    const isDisabled = item && item.itemType === itemTypes.EDITORIAL;

    let contentSourceDropdown = (
        <DialogDropdownSingle
            options={contentSourceTypeOptions}
            value={
                contentSourceTypeOptions.find((option) => option.value === contentSourceType) ||
                (itemType === itemTypes.EDITORIAL && contentSourceTypeOptions.find((option) => option.value === itemTypes.EDITORIAL))
            }
            placeholder={'Content Sources'}
            labelText={'Content Sources'}
            onChange={(value: any) => {
                setContentSource('');
                setDynamicSourceId('');
                setSingleAsset(false);
                setSingleAssetId('');
                if (value.value === itemTypes.EDITORIAL) {
                    setItemType(itemTypes.EDITORIAL);
                    setContentSourceType(undefined);
                } else {
                    setItemType(itemTypes.DYNAMIC);
                    setContentSourceType(value.value);
                }
                setErrors(_.omit(errors, ['contentSourceType', 'contentSource']));
            }}
            error={errors.contentSourceType}
            isDisabled={isDisabled}
            withTopMargin
            dataCy={'item-content-sources-dropdown'}
            toolTipText={
                isCollection
                    ? 'The collection can be composed of either one item with a Dynamic Source or multiple Editorial items'
                    : isDisabled
                    ? `An Item's Content Source can not be changed from editorial to a dynamic one`
                    : undefined
            }
        />
    );
    const isEditorial = itemType === itemTypes.EDITORIAL;
    const nameInTitle = itemType ? (isEditorial ? 'Editorial Item' : 'Dynamic List') : 'List or Item';

    const warnings: any[] = [];
    if (shouldRenderAutoCollectionAlert) {
        warnings.push({ icon: icons.warningIcon, critical: true, onClick: () => renderAutoCollectionAlertDialog() });
    }

    if (shouldRenderIncludeProviderCardLogoAlert) {
        warnings.push({ icon: icons.infoIcon, onClick: () => renderIncludeProviderCardLogoAlertDialog() });
    }

    const renderOptions = () => {
        return (
            <OptionsContainer>
                <OptionEntry>
                    <DialogCheckbox
                        onClick={() => setVideoAutoplay(!videoAutoplay)}
                        text={'Video Auto-Play'}
                        value={videoAutoplay}
                        active={videoAutoplay}
                    />
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'item_video_autoplay_checkbox')}
                </OptionEntry>
                {itemType === itemTypes.DYNAMIC && (
                    <>
                        <OptionEntry>
                            <DialogToggleButton
                                checked={alterateBehaviour}
                                text={'Alterate Behaviour'}
                                toggleCallback={() => {
                                    if (alterateBehaviour) setAlterateBehaviourLabel('');
                                    setErrors(_.omit(errors, 'alterateBehaviourLabel'));
                                    setAlterateBehaviour(!alterateBehaviour);
                                }}
                                tooltipText="module_alterate_behaviour"
                            />
                            {alterateBehaviour && (
                                <ToggleExtraFieldContainer>
                                    <ConfigurableSelect
                                        type={ConfigurableKeys.ITEM_ALTERATE_BEHAVIOUR_LABEL}
                                        selectedValue={alterateBehaviourLabel}
                                        onSelectedValuesChange={(value) => {
                                            setAlterateBehaviourLabel(value);
                                            setErrors(_.omit(errors, 'alterateBehaviourLabel'));
                                        }}
                                        newValueTooltipKey="module_alterate_behaviour_label"
                                        tooltipKey="module_alterate_behaviour_label_select" // for now the tooltips are the same for item and module
                                        error={errors.alterateBehaviourLabel}
                                    />
                                </ToggleExtraFieldContainer>
                            )}
                        </OptionEntry>
                        <OptionEntry>
                            <DialogToggleButton
                                checked={singleAsset}
                                toggleCallback={() => {
                                    const newValue = !singleAsset;
                                    // if single asset is turned on, set max number of items to nothing, otherwise set singleAsset id to empty
                                    newValue ? setMaxNumberOfItems('') : setSingleAssetId('');
                                    setErrors(_.omit(errors, 'singleAssetId'));
                                    setSingleAsset(newValue);
                                }}
                                text={'Single Asset'}
                                tooltipText={'item_single_asset_checkbox'}
                            />
                            {singleAsset && (
                                <ToggleExtraFieldContainer>
                                    <DialogTextField
                                        placeholder={'Enter Asset id for the single asset'}
                                        label={'Select Asset Id'}
                                        value={singleAssetId}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setSingleAssetId(e.target.value);
                                            setErrors(_.omit(errors, 'singleAssetId'));
                                        }}
                                        error={errors.singleAssetId}
                                    />
                                </ToggleExtraFieldContainer>
                            )}
                        </OptionEntry>
                    </>
                )}

                {itemType === itemTypes.EDITORIAL && (
                    <>
                        <OptionEntry>
                            <DialogCheckbox
                                onClick={() => {
                                    setContentWorldType(!showContentWorld ? ContentWorldTypes.SELECTABLE : '');
                                    setShowContentWorld(!showContentWorld);
                                }}
                                text={'Content World Type'}
                                value={showContentWorld}
                                active={showContentWorld}
                            />
                            {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'item_content_world')}
                        </OptionEntry>

                        {showContentWorld && (
                            <ContentWorldExtraFields>
                                {Object.values(ContentWorldTypes).map((key) => {
                                    return (
                                        <ContentWorldField key={`cw_${key}`}>
                                            <DialogRadioButton
                                                onClick={() => setContentWorldType(key)}
                                                text={_.capitalize(key)}
                                                active={contentWorldType === key}
                                            />
                                            {renderTooltip(
                                                <SVGInline src={icons.infoIcon} />,
                                                tooltipTypes.TEXT,
                                                contentWorldToolTips[key] || '',
                                                tooltipPositions.TOP
                                            )}
                                        </ContentWorldField>
                                    );
                                })}
                            </ContentWorldExtraFields>
                        )}
                    </>
                )}
            </OptionsContainer>
        );
    };

    const targetConditionsInfo =
        !item || !item?.placed?.length
            ? `The ${
                  itemType ? (isEditorial ? 'Editorial Item' : 'Dynamic List') : 'List or Item'
              } will automatically inherit the Module’s Audiences when added to it.`
            : `The ${itemType ? (isEditorial ? 'Editorial Item' : 'Dynamic List') : 'List or Item'} has one or multiple parents.
                            Navigate to the parent objects to check the exact targeting conditions.`;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={item ? (duplicate ? `Duplicate ${nameInTitle}` : `Edit ${nameInTitle}`) : `Create ${nameInTitle}`}
            onClose={() => handleCloseClick()}
            actionButtons={[cancelButton, saveButton]}
            dataCy={'new-dialog-item'}
            warnings={warnings}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.items }}
        >
            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                }}
                label={'Name'}
                placeholder={'Name'}
                optional={true}
                toolTipText={'Provide a meaningful name for better searchability'}
                withTranslations
                dataCy={'item-name-input'}
            />
            {contentSourceDropdown}
            {!!contentSourceType && itemType !== itemTypes.EDITORIAL && (
                <>
                    {!RealDynamicContentSourceTypes.includes(contentSourceType) ? (
                        <DialogDropdownSingle
                            placeholder={'Select Content'}
                            options={contentSourceOptions}
                            value={contentSourceOptions.find((option) => option.value === contentSource) || ''}
                            onChange={(value: any) => {
                                setContentSource(value.value);
                                setErrors(_.omit(errors, 'contentSource'));
                            }}
                            error={errors.contentSource}
                            withTopMargin
                        />
                    ) : (
                        <DialogDropdownSingle
                            placeholder={'Select Source'}
                            options={dynamicSourceOptions}
                            value={dynamicSourceOptions.find((source) => source.value === dynamicSourceId) || ''}
                            onChange={(value: any) => {
                                setSingleAsset(false);
                                setSingleAssetId('');
                                setDynamicSourceId(value.value);
                            }}
                            newOption={{ name: 'Dynamic Source', onClick: () => setOpenCreateNew(true) }}
                            isDisabled={sourcesLoading}
                            withTopMargin
                        />
                    )}
                    <DialogTextField
                        value={maxNumberOfItems}
                        onChange={(e: any) => {
                            if (e.target.value.length > 3) return;
                            setMaxNumberOfItems(e.target.value);
                        }}
                        label={'Maximum Number of Items: (Unlimited if nothing set)'}
                        isDisabled={singleAsset}
                        withTopMargin
                        specificSanitization={/[^0-9]/g}
                    />
                </>
            )}

            <TargetConditionsWrapper>
                <label>
                    <InputLabelWithIconWrapper>
                        Target Conditions
                        {renderTooltip(
                            <SVGInline src={icons.infoIcon} />,
                            tooltipTypes.TEXT,
                            targetConditionsType === TargetConditionsTypes.MANUAL
                                ? 'Select an existing or create a new condition'
                                : 'The target conditions will be automatically inherited from the parent object',
                            tooltipPositions.TOP
                        )}
                    </InputLabelWithIconWrapper>
                </label>
                <RadioButtonsWrapper>
                    <DialogRadioButton
                        onClick={() => {
                            setTargetConditionsType(TargetConditionsTypes.PARENT);
                            setErrors(_.omit(errors, 'targets'));
                            setAbTestingGroupIds([]);
                            setTargets([]);
                        }}
                        active={targetConditionsType === TargetConditionsTypes.PARENT}
                        text={'Parent'}
                    />
                    <DialogRadioButton
                        onClick={() => setTargetConditionsType(TargetConditionsTypes.MANUAL)}
                        active={targetConditionsType === TargetConditionsTypes.MANUAL}
                        text={'Manual'}
                    />
                </RadioButtonsWrapper>
            </TargetConditionsWrapper>
            {targetConditionsType === TargetConditionsTypes.PARENT ? (
                <TargetConditionsInfoWrapper>
                    {/* If selectedPage it means the dialog in Visual Editorial and we need to display the Audiences */}
                    {_.isEmpty(selectedPage?.conditions) && _.isEmpty(module?.conditions) ? (
                        <TargetConditionsInfo>{targetConditionsInfo}</TargetConditionsInfo>
                    ) : (
                        <Labels
                            values={_.isEmpty(module?.conditions) ? selectedPage?.conditions : module?.conditions || []}
                            type={MoreInfoTypes.TARGETS}
                            noOfLabels={3}
                            blackLabels
                        />
                    )}
                </TargetConditionsInfoWrapper>
            ) : (
                <TargetConditionsSelect
                    id={'item-target-conditions'}
                    error={errors.targets}
                    targets={targets}
                    withoutLabel
                    inDialog
                    onChange={(val: any) => {
                        setTargets(val);
                        setErrors(_.omit(errors, 'targets'));
                    }}
                />
            )}

            <ABTestingGroupSelect
                id={'item-ab-testing-groups'}
                inDialog
                onChange={setAbTestingGroupIds}
                selectedGroups={abTestingGroupIds}
                selectedTargetConditions={targets}
                isDisabled={targetConditionsType === TargetConditionsTypes.PARENT}
            />

            <OptionSection>
                <OptionsToggle onClick={() => setShowOptions(!showOptions)}>
                    Options <SVGInline src={showOptions ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </OptionsToggle>
                {showOptions && renderOptions()}
            </OptionSection>

            <NewSourceDialog
                open={openCreateNew}
                onClose={() => {
                    setOpenCreateNew(false);
                }}
                onSave={(source) => {
                    createDynamicSource(source);
                }}
                preselectedService={contentSourceType}
            />
        </GenericDialog>
    );
};

export const renderAutoCollectionAlertDialog = () => {
    dialogAlert(DIALOG_NAMES.DYNAMIC_ITEM_AUTOCOLLECTION, false, undefined, undefined, false, icons.warningIcon, () => {});
};

export const renderIncludeProviderCardLogoAlertDialog = () => {
    dialogAlert(DIALOG_NAMES.ITEM_PROVIDER_LOGO_CARD, false, undefined, undefined, false, icons.warningYellowIcon, () => {});
};
