import { FC, useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../common/Dialog/GenericDialog';
import { validator } from '../../utils/fnValidator';
import _ from 'lodash';

type ApplicationProps = {
    open: boolean;
    onClose: () => void;
    onSave: (arg: any) => void;
    editingApplicationValues?: Application;
};

export type Application = {
    name: string;
    androidPackageName: string;
    iOSDeepLink: string;
    webSmartTVPackageId: string;
    webURL: string;
};
export type ApplicationValues = Omit<Application, 'name'>;
export type ApplicationErrorFields = {
    [key in keyof Application]?: string;
};

const ApplicationFields: (keyof Application)[] = ['name', 'androidPackageName', 'iOSDeepLink', 'webSmartTVPackageId', 'webURL'];
export const EMPTY_APPLICATION_VALUES = {
    name: '',
    androidPackageName: '',
    iOSDeepLink: '',
    webSmartTVPackageId: '',
    webURL: ''
} as const;

export type ApplicationFieldTooltips = {
    [key in keyof ApplicationValues]: string;
};
export const ApplicationFieldTooltipsValues: ApplicationFieldTooltips = {
    androidPackageName: 'Use this field when the client runs on an android device',
    iOSDeepLink: 'Use this field when the client runs on an iOS/tvOS device',
    webSmartTVPackageId: 'Use this field when the client runs on a non-android smartTV',
    webURL: 'Use this field when the client runs on a web browser'
};

export const ApplicationFieldLabelValues: ApplicationFieldTooltips = {
    androidPackageName: 'Android Package Name',
    iOSDeepLink: 'iOS Deeplink',
    webSmartTVPackageId: 'Web Smart TV Package ID',
    webURL: 'Web URL'
};

export const ApplicationDialog: FC<ApplicationProps> = ({ open, onClose, onSave, editingApplicationValues }) => {
    const [applicationValues, setApplicationValues] = useState<Application>(EMPTY_APPLICATION_VALUES);
    const [applicationErrors, setApplicationErrors] = useState<ApplicationErrorFields>({});
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        open && editingApplicationValues && setApplicationValues(editingApplicationValues);
        setIsOpen(open);
    }, [open]);

    const validateApplication = () => {
        const errors = { ...applicationErrors };
        Object.keys(applicationValues).forEach((key) => {
            if (!['name', 'androidPackageName'].includes(key)) return;
            errors[key as keyof ApplicationErrorFields] = validator({ required: true }, applicationValues[key as keyof Application]);
        });

        setApplicationErrors(errors);

        return !Object.values(errors).filter((value) => !!value).length;
    };

    const handleSaveClick = () => {
        if (!validateApplication()) return;
        onSave(applicationValues);
        handleCloseClick();
    };

    const handleCloseClick = () => {
        setApplicationValues(EMPTY_APPLICATION_VALUES);
        setApplicationErrors({});
        onClose();
    };
    const addButton: DialogButton = {
        label: `${editingApplicationValues ? 'Save' : 'Add'}`,
        type: 'BLUE',
        onClick: handleSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!isOpen) return null;

    return (
        <GenericDialog
            type={DialogTypes.Form}
            title={`${editingApplicationValues ? 'Edit' : 'Add'} Application`}
            actionButtons={[cancelButton, addButton]}
            onClose={handleCloseClick}
        >
            {ApplicationFields.map((field) => {
                return (
                    <DialogTextField
                        key={`application_${field}`}
                        toolTipText={field !== 'name' ? ApplicationFieldTooltipsValues[field] : ''}
                        label={field !== 'name' ? ApplicationFieldLabelValues[field] : ''}
                        value={applicationValues[field]}
                        onChange={(e: any) => {
                            setApplicationErrors(_.omit(applicationErrors, field));
                            setApplicationValues({ ...applicationValues, [field]: e.target.value });
                        }}
                        error={applicationErrors?.[field]}
                        isDisabled={editingApplicationValues && field === 'name'}
                        isUrl={field !== 'name'}
                    />
                );
            })}
        </GenericDialog>
    );
};
