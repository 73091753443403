import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { TargetGroup, TargetGroupAbTestingStatus, TargetGroupForUpdate } from '../../types/TargetGroup';

export const fetchTargetGroups = createAsyncThunk<
    { targetGroups: TargetGroup[]; error: { message: string; code: string } | null; permissions?: any },
    any | undefined
>('targetGroup/fetchTargetGroups', async ({ addPermissions, projectId, full }, thunkApi) => {
    const result = await ConfigServiceAPI.getAllTargetGroups(addPermissions, projectId, full);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { targetGroups: result.response as any, error: null, permissions: result.permissions };
});

export const fetchTargetGroup = createAsyncThunk<
    { targetGroup: TargetGroup; error: { message: string; code: string } | null },
    { targetGroupId: string }
>('targetGroup/fetchTargetGroup', async ({ targetGroupId }, thunkApi) => {
    const result = await ConfigServiceAPI.getTargetGroupById(targetGroupId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { targetGroup: result.response as any as TargetGroup, error: null };
});

export const createTargetGroup = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, TargetGroup>(
    'targetGroup/createTargetGroup',
    async (targetGroup: TargetGroup, thunkApi) => {
        const result = await ConfigServiceAPI.createTargetGroup(targetGroup);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateTargetGroup = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; republishedObjects?: boolean },
    { targetGroup: TargetGroupForUpdate; objectsToUpdate?: { id: string; shouldRepublish: boolean }[] }
>('targetGroup/updateTargetGroup', async ({ targetGroup, objectsToUpdate }, thunkApi) => {
    const result = await ConfigServiceAPI.updateTargetGroup(targetGroup, objectsToUpdate);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    const response = result.response as any;
    return { ok: !!response, error: null, republishedObjects: response?.republishedObjects };
});

export const changeTargetGroupAbTestingStatus = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; republishedObjects?: boolean },
    { targetGroupId: string; abTestingStatus: TargetGroupAbTestingStatus }
>('targetGroup/changeTargetGroupAbTestingStatus', async ({ targetGroupId, abTestingStatus }, thunkApi) => {
    const result = await ConfigServiceAPI.changeAbTestingStatus(targetGroupId, abTestingStatus);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    const response = result.response as any;
    return { ok: !!response, error: null, republishedObjects: response?.republishedObjects };
});

export const deleteTargetGroup = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'targetGroup/deleteTargetGroup',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteTargetGroup(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export interface targetGroupsState {
    targetGroups: TargetGroup[];
    selectedTargetGroup?: TargetGroup;
    loading: boolean;
    loadingGroup: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
}

const initialState: targetGroupsState = {
    targetGroups: [],
    loading: false,
    loadingGroup: false,
    error: null
};

const slice = createSlice({
    name: 'targetGroup',
    initialState,
    reducers: {
        unsetTargetGroupError(state) {
            state.error = null;
        },
        unsetSelectedTargetGroup(state) {
            state.selectedTargetGroup = undefined;
        },
        unsetTargetGroups(state) {
            state.targetGroups = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTargetGroups.fulfilled, (state, action: any) => {
                state.targetGroups = action.payload.targetGroups;
                state.error = state.error;
                state.loading = false;
            })
            .addCase(fetchTargetGroups.rejected, (state, action: any) => {
                state.targetGroups = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchTargetGroups.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchTargetGroup.fulfilled, (state, action: any) => {
                state.selectedTargetGroup = action.payload.targetGroup;
                state.error = null;
                state.loadingGroup = false;
            })
            .addCase(fetchTargetGroup.rejected, (state, action: any) => {
                state.selectedTargetGroup = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingGroup = false;
            })
            .addCase(fetchTargetGroup.pending, (state, _action) => {
                state.loadingGroup = true;
            })
            .addCase(createTargetGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createTargetGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createTargetGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateTargetGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateTargetGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateTargetGroup.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(changeTargetGroupAbTestingStatus.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(changeTargetGroupAbTestingStatus.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(changeTargetGroupAbTestingStatus.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteTargetGroup.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteTargetGroup.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteTargetGroup.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export const { unsetTargetGroupError, unsetSelectedTargetGroup, unsetTargetGroups } = slice.actions;
export default slice.reducer;
