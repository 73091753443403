import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../../hooks/redux';
import { fetchGroups, GroupsState } from '../../../../redux/slices/groupsSlice';
import { fetchProjects, ProjectsState } from '../../../../redux/slices/projectsSlice';
import { fetchRoles, RolesState } from '../../../../redux/slices/rolesSlice';
import { fetchTenants, TenantsState } from '../../../../redux/slices/tenantsSlice';
import icons from '../../../../style';
import { StyledImage } from '../../../../style/styled-components/reusable.css';
import { Role } from '../../../../types/Role';
import { User } from '../../../../types/User';
import { API_ERROR_CODES, DROPDOWN_GROUP_LABEL_VALUE, getImgixUrl } from '../../../../utils/Globals';
import GenericDialog, {
    DialogButton,
    DialogCheckbox,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogTextField,
    DialogTypes
} from '../../../common/Dialog/GenericDialog';
import { DialogDoubleFieldWrapper, ErrorLabel } from '../../../common/Dialog/GenericDialog.css';
import { AvatarHolder, CameraIconHolder, MyProfileAvatar, MyProfileCameraSVGInline } from '../../../common/Profile/Profile.css';
import { PermissionsState } from '../../../../redux/slices/permissionsSlice';
import BackendErrorDialog from '../../../common/Dialog/BackendErrorDialog';
import { validator } from '../../../../utils/fnValidator';
import { FilesState, uploadFilesSync } from '../../../../redux/slices/fileManagerSlice';
import { extractFileNameFromAzureURL } from '../../../../utils/fnUrl';
import { dialogAlert, dialogConfirm } from '../../../../utils/fnDialogs';
import { Project } from '../../../../types/Project';
import { RemoveModuleWrapper } from '../../../Modules/Modules.css';
import SVGInline from 'react-inlinesvg';
import { UsersState } from '../../../../redux/slices/usersSlice';
import configServiceAPI from '../../../../utils/api/configServiceAPI';
import { RoleWrapper } from '../Users.css';
import { CIRCLE_SLUGS } from '../../../common/HelpIcon/HelpIcon';

export type NewUserDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (value: User) => void;
    user?: User | null;
};

const NewUserDialog: FC<NewUserDialogProps> = ({ open, onClose, user, onSave }) => {
    const { tenants, error: tenantsError, loading: tenantsLoading }: TenantsState = useAppSelector((state) => state.tenants);
    const { projects, error: projectsError, loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);
    const { groups, error: groupsError, loading: groupsLoading }: GroupsState = useAppSelector((state) => state.groups);
    const { roles, error: rolesError, loading: rolesLoading }: RolesState = useAppSelector((state) => state.roles);
    const { users }: UsersState = useAppSelector((state) => state.users);
    const { error: imageError, loading: imageLoading }: FilesState = useAppSelector((state) => state.files);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { config } = useAppSelector((state) => state.config);
    const [defaultRole, setDefaultRole] = useState<Role | undefined>(undefined);
    const [image, setImage] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>(user?.fullName || '');
    const [email, setEmail] = useState<string>(user?.email || '');
    const [tenantsIds, setTenantsIds] = useState<string[]>(user?.tenantIds || []);
    const [groupsIds, setGroupsIds] = useState<string[]>(user?.userGroupIds || []);
    const [projectsIds, setProjectsIds] = useState<string[]>(user?.projectIds || []);
    const [error, setError] = useState<{ name?: string; tenants?: string; email?: string }>({});
    const [roleId, setRoleId] = useState<string | undefined>(user?.roleId);
    const [isOpen, setIsOpen] = useState(open);
    const [deleteImage, setDeleteImage] = useState(false);
    const imageRef = useRef<any>(null);
    const acceptedExtensions = ['.jpg', '.png'];

    const dispatch = useDispatch();

    const validateUser = () => {
        const newError = { ...error };
        newError.name = validator({ required: true }, fullName);
        newError.email = validator({ required: true, minLength: 5, email: true }, email);
        newError.tenants = !isSuperAdmin && tenants?.length !== 1 ? validator({ required: true, minLength: 1 }, tenantsIds) : '';

        setError(newError);
        return Object.values(newError).filter((value) => !!value).length === 0;
    };

    const removeProjectLead = async (project: Project, userId: string) => {
        const updatedMembers = users?.filter((u) => u.projectIds?.includes(project._id) && u._id !== userId)?.map((u) => u._id) || [];

        const response = await configServiceAPI.updateProjectMembers(project._id, updatedMembers);
        if (response.error) {
            const values = {
                title: 'Oops.. Could not update members!',
                subtitle: `Something went wrong while updating the project's members.`,
                text: 'An error occured: ' + response.error.message
            };
            dialogAlert('', false, values);
        }
    };

    const renderProjectLeadWarning = (projects: Project[], userId: string) => {
        const projectNames = projects.map((project) => project.name);
        const message = `The User is a project lead in ${projectNames.join(', ')}`;
        const values = {
            title: 'Warning!',
            text: ''
        };
        return dialogConfirm(
            '',
            async () => {
                await Promise.all(projects.map((project) => removeProjectLead(project, userId)));
                onSaveClick(true);
            },
            values,
            <RemoveModuleWrapper>
                <SVGInline src={icons.warningIcon} />
                <p>
                    <strong>{message}</strong>
                    <br />
                    Do you wish to remove the user from the project(s)?
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Ok'
                }
            }
        );
    };

    const onSaveClick = async (withoutWarning?: boolean) => {
        if (!onSave) return;
        if (!validateUser()) return;
        let newUser: User;
        if (isSuperAdmin) {
            newUser = {
                _id: user?._id || '',
                fullName,
                email,
                superAdmin: true,
                state: 0
            };
        } else {
            newUser = {
                _id: user?._id || '',
                roleId,
                email,
                fullName,
                tenantIds: tenants?.length === 1 ? [tenants[0]._id] : tenantsIds,
                projectIds: projectsIds,
                userGroupIds: groupsIds,
                state: 0
            };
        }

        if (image) {
            const urls = await createFile(image);
            if (urls?.length) {
                const fileName = extractFileNameFromAzureURL(config.AZURE_CONFIG.account, config.AZURE_CONFIG.container, urls[0]);
                newUser.icon = encodeURIComponent(getImgixUrl(config.imgixBaseUrl, fileName));
            }
        } else if (deleteImage) {
            newUser.icon = '';
        }

        if (user && !withoutWarning) {
            const projectsWithProjectLead = projects.filter((project) => project.projectLeadIds?.includes(user._id));
            const projectsToRemove = projectsWithProjectLead.filter((project) => !newUser.projectIds?.includes(project._id));
            if (projectsToRemove.length) {
                return renderProjectLeadWarning(projectsToRemove, user._id);
            }
        }

        onSave(newUser);
        setImage(null);
        setFullName('');
        setProjectsIds([]);
        setGroupsIds([]);
        setEmail('');
        setRoleId(defaultRole?._id || '');
        setIsSuperAdmin(false);
        setTenantsIds([]);
        setError({});
        setPreview(null);
    };

    const onCloseClick = () => {
        if (onClose) {
            onClose();
            setImage(null);
            setFullName('');
            setProjectsIds([]);
            setGroupsIds([]);
            setTenantsIds([]);
            setEmail('');
            setRoleId(defaultRole?._id || '');
            setIsSuperAdmin(false);
            setError({});
            setPreview(null);
        }
    };

    const loadTenants = async () => {
        return await dispatch(fetchTenants({})).unwrap();
    };
    const loadProjects = async () => {
        await dispatch(fetchProjects({})).unwrap();
    };
    const loadGroups = async () => {
        await dispatch(fetchGroups({})).unwrap();
    };
    const loadRoles = async () => {
        await dispatch(fetchRoles()).unwrap();
    };

    const createFile = async (file: File) => {
        const newFile = new File([file], `${file.name}-${user?._id}`, { type: file.type });
        const prefix = 'user_icons';
        try {
            const response = await dispatch(uploadFilesSync({ files: [newFile], prefix, overwrite: true })).unwrap();
            return response.urls;
        } catch (ex) {
            return [];
        }
    };

    useEffect(() => {
        if (open) {
            loadTenants().then((response) => {
                if (response.error?.code !== API_ERROR_CODES.AUTHENTICATION_ERROR) {
                    loadProjects();
                    loadGroups();
                    loadRoles();
                    setIsOpen(true);
                }
            });
        } else {
            setIsOpen(false);
        }
        setDeleteImage(false);
    }, [open]);

    useEffect(() => {
        if (user) {
            setFullName(user.fullName);
            setEmail(user.email);
            setTenantsIds(user.tenantIds || []);
            setGroupsIds(user.userGroupIds || []);
            setProjectsIds(user.projectIds || []);
            setRoleId(user.roleId);
            setIsSuperAdmin(user?.role?.name === 'superAdmin');
            setPreview(user.icon || '');
        }
    }, [user]);

    useEffect(() => {
        if (!isSuperAdmin) {
            setRoleId(defaultRole?._id || '');
        }
    }, [isSuperAdmin]);

    useEffect(() => {
        if (rolesLoading || rolesError) return;
        if (roles?.length) {
            setDefaultRole(roles.find((role: Role) => role.name === 'Editor'));
        }
    }, [rolesLoading]);

    useEffect(() => {
        if (user) return;
        if (defaultRole) {
            setRoleId(defaultRole._id);
        }
    }, [defaultRole]);

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: () => onSaveClick(),
        loading: imageLoading,
        disabled: projectsLoading
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onCloseClick
    };

    let projectValueOptions: any[] = [];
    const selectedTenants =
        tenants?.length === 1
            ? [tenants[0]]
            : tenants?.filter((tenant) => {
                  return tenantsIds?.includes(tenant._id);
              });

    const projectOptions = selectedTenants.map((tenant) => {
        // If The user is TenantAdmin do not show the Projects of that Tenant
        if (user?._id && tenant.tenantAdminIds.includes(user._id)) {
            return {};
        }

        const projectsPerTenant = projects?.filter((project) => {
            return project.tenantId?.includes(tenant._id);
        });
        const options = projectsPerTenant.map((option: any) => {
            return {
                label: option.name,
                value: option._id
            };
        });

        options.forEach((value: any) => {
            projectValueOptions.push(value);
        });

        return {
            label: tenant.name,
            value: DROPDOWN_GROUP_LABEL_VALUE,
            options: options
        };
    });

    const projectsValues = projectValueOptions?.filter((option: any) => {
        return projectsIds?.includes(option.value);
    });

    const tenantsOptions = tenants?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant._id
        };
    });

    const tenantsValues = tenantsOptions.filter((option: any) => {
        return tenantsIds?.includes(option.value);
    });

    const roleOptions = roles
        ?.filter((role) => role.name !== 'superAdmin')
        .map((role) => {
            return {
                label: role.name,
                value: role._id
            };
        });

    const roleValue = roleOptions?.filter((option: any) => {
        return roleId === option.value;
    });

    let groupValueOptions: any[] = [];
    const groupsOptions = selectedTenants.map((tenant) => {
        // If The user is TenantAdmin do not show the UserGroups of that Tenant
        if (user?._id && tenant.tenantAdminIds.includes(user._id)) {
            return {};
        }
        const groupsPerTenant = groups.filter((group) => group.tenantId === tenant._id);
        const options = groupsPerTenant
            .filter((group) => projectsIds.some((project) => group.projectIds.includes(project)))
            .map((option) => {
                return {
                    label: option.name,
                    value: option._id
                };
            });

        options.forEach((value: any) => {
            groupValueOptions.push(value);
        });

        return {
            label: tenant.name,
            options
        };
    });

    const groupsValues = groupValueOptions?.filter((group: any) => {
        return groupsIds?.includes(group.value);
    });

    const handleImageInputChange = (e: any) => {
        const newImage = e.target?.files?.[0];
        if (newImage) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(newImage);
                setPreview(reader.result);
            };
            reader.readAsDataURL(newImage);
        }
        setDeleteImage(!newImage);
        setError(_.omit(error, 'icon'));
    };

    const filterProjectsPerTenants = () => {
        if (!projects) return;
        const projectsIdsPerTenants = projects.filter((project) => tenantsIds?.includes(project.tenantId)).map((item) => item._id);

        const filteredProjects = projectsIdsPerTenants?.filter((id) => projectsIds?.includes(id));
        setProjectsIds(filteredProjects);
    };
    const filterGroupsPerProjects = () => {
        if (!groups) return;
        const groupsIdsPerProjects = groups
            .filter((group) => projectsIds.some((project) => group.projectIds.includes(project)))
            .map((item) => item._id);
        const filteredGroups = groupsIdsPerProjects?.filter((id) => groupsIds?.includes(id));
        setGroupsIds(filteredGroups);
    };

    useEffect(() => {
        filterProjectsPerTenants();
    }, [tenantsIds]);

    useEffect(() => {
        filterGroupsPerProjects();
    }, [projectsIds]);

    const handleSelectChange = (value: any, type: 'tenant' | 'project' | 'group') => {
        switch (type) {
            case 'tenant':
                setTenantsIds([...value.map((v: any) => v.value)]);
                break;
            case 'project':
                setProjectsIds([...value.map((v: any) => v.value)]);
                break;
            case 'group':
                setGroupsIds([...value.map((v: any) => v.value)]);
                break;
            default:
                null;
        }
    };

    const uploadImage = () => {
        if (imageRef.current) {
            imageRef.current.click();
        }
    };

    // this is outside the main return because we do not open the dialog if we have error
    // so isOpen will be false
    const beError = tenantsError || projectsError || groupsError || rolesError;
    if (beError) {
        return <BackendErrorDialog error={beError} />;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <GenericDialog
            type={DialogTypes.Form}
            actionButtons={[cancelButton, saveButton]}
            onClose={onCloseClick}
            title={user ? 'Edit User' : 'Create User'}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.users }}
        >
            <MyProfileAvatar>
                <AvatarHolder>
                    <StyledImage src={(preview as string) || icons.userIcon} />
                    <CameraIconHolder onClick={uploadImage}>
                        <MyProfileCameraSVGInline src={icons.cameraIcon2} />
                    </CameraIconHolder>
                </AvatarHolder>
                <input
                    id="file-upload-input"
                    type="file"
                    accept={acceptedExtensions.join(',')}
                    onChange={(e) => {
                        handleImageInputChange(e);
                    }}
                    ref={imageRef}
                    hidden
                />
            </MyProfileAvatar>
            <ErrorLabel error={imageError?.message !== undefined}>{imageError?.message || ''}</ErrorLabel>
            {/* this will only appear for super admins, tenant admins don't have the option to create super admins*/}
            {userPermissions?.isSuperAdmin && (
                <DialogCheckbox
                    value={isSuperAdmin}
                    active={isSuperAdmin}
                    text={'Super admin'}
                    onClick={(e: any) => {
                        setIsSuperAdmin(!isSuperAdmin);
                    }}
                    position={'center'}
                />
            )}
            {!isSuperAdmin ? (
                <DialogDoubleFieldWrapper $width1={388}>
                    <DialogTextField
                        label={'Full Name'}
                        value={fullName}
                        onChange={(e: any) => {
                            setFullName(e.target.value);
                            setError(_.omit(error, 'name'));
                        }}
                        placeholder={'Add full Name'}
                        error={error.name}
                        maxLength={50}
                        withTopMargin
                    />
                    <RoleWrapper>
                        <DialogDropdownSingle
                            labelText={'Default role'}
                            value={roleValue}
                            options={roleOptions}
                            placeholder={''}
                            onChange={(value: any) => {
                                setRoleId(value.value);
                            }}
                            withTopMargin
                        />
                    </RoleWrapper>
                </DialogDoubleFieldWrapper>
            ) : (
                <DialogTextField
                    label={'Full Name'}
                    value={fullName}
                    onChange={(e: any) => {
                        setFullName(e.target.value);
                        setError(_.omit(error, 'name'));
                    }}
                    placeholder={'Add full Name'}
                    error={error.name}
                    withTopMargin
                />
            )}
            <DialogTextField
                label={'Email'}
                value={email}
                onChange={(e: any) => {
                    setEmail(e.target.value);
                    setError(_.omit(error, 'email'));
                }}
                placeholder={'Add email'}
                error={error.email}
                withTopMargin
                withoutSanitization
            />

            {!isSuperAdmin && (
                <>
                    {/* this will only appear for super admins or users that are tenant admins in more than one tenant
                     * they will need to select for which tenant the project is created
                     */}
                    {tenants?.length > 1 && (
                        <DialogDropdownMultiple
                            placeholder={'Add Tenant(s)'}
                            options={tenantsOptions}
                            value={tenantsValues}
                            labelText={'Tenant(s)'}
                            onChange={(value: any) => {
                                setError(_.omit(error, 'tenants'));
                                if (value === null) return setTenantsIds([]);
                                handleSelectChange(value, 'tenant');
                            }}
                            allowSelectAll={true}
                            error={error.tenants}
                            withTopMargin
                        />
                    )}
                    <DialogDropdownMultiple
                        onChange={(value: any) => {
                            if (value === null) return setProjectsIds([]);
                            handleSelectChange(value, 'project');
                        }}
                        placeholder={'Add Project(s)'}
                        options={projectOptions}
                        value={projectsValues}
                        labelText={'Project(s)'}
                        allowSelectAll={true}
                        optional={true}
                        toolTipText={'Select projects to assign the user to!'}
                        isDisabled={projectsLoading}
                        withTopMargin
                    />
                    <DialogDropdownMultiple
                        placeholder={'Add Group(s)'}
                        options={groupsOptions}
                        value={groupsValues}
                        labelText={'Group(s)'}
                        onChange={(value: any) => {
                            if (value === null) return setGroupsIds([]);
                            handleSelectChange(value, 'group');
                        }}
                        allowSelectAll={true}
                        optional={true}
                        toolTipText={'Select groups to assign the user to!'}
                        withTopMargin
                    />
                </>
            )}
        </GenericDialog>
    );
};

export default NewUserDialog;
