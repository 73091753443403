import React, { FC, useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

import { TruncatedText } from '../../../style/styled-components/reusable.css';
import GenericDialog, { DialogButton, DialogTypes } from './GenericDialog';
import {
    ActivityFieldStatus,
    ActivityDialogInfo,
    ActivitiesWrapper,
    ActivityContainer,
    ActivityFieldKey,
    ActivityDetails,
    ObjectTypeIcon,
    ActivityField,
    ArrowIcon,
    UserActivityInfo,
    UserActivityDetails,
    UserActivityField,
    UserActivityFieldTitle
} from './ObjectActivityDialog.css';

import { formatObjectActivityKey, RenamedKeysType } from '../../../utils/fnString';
import { ActivityTreeNode, ObjectActivityType, ObjectActivityTypes, ObjectType, parsedObjectActivities } from '../../../types/Object';
import { EMPTY_WORD_STRING, getIconByObjectType } from '../../../utils/Globals';
import { useAppSelector, useAppDispatch as useDispatch } from '../../../hooks/redux';
import { CIRCLE_SLUGS } from '../HelpIcon/HelpIcon';
import icons from '../../../assets/images/icons';
import SVGInline from 'react-inlinesvg';
import useTranslation from '../../../hooks/useTranslation';
import { getUserActivity, ObjectActivityState } from '../../../redux/slices/objectActivitySlice';
import { getDateAndTime } from '../../../utils/fnDate';
import { renderChildrenTree } from './ObjectActivityDialog';

export type UserActivityDialogProps = {
    open: boolean;
    userId: string;
    title: string;
    onClose: () => void;
};

const UserActivityDialog: FC<UserActivityDialogProps> = ({ open, userId, title, onClose }) => {
    const { userActivity, loading }: ObjectActivityState = useAppSelector((state) => state.objectActivity);
    const { translate } = useTranslation();
    const [showActivityDetails, setShowActivityDetails] = useState<string>('');
    const [userActivities, setUserActivities] = useState<parsedObjectActivities | undefined>(undefined);

    const dispatch = useDispatch();

    const handleArrowClick = (id: string) => {
        setShowActivityDetails((prevOpen) => (prevOpen === id ? '' : id));
    };

    const handleOnClose = () => {
        setShowActivityDetails('');
        setUserActivities({});
        onClose();
    };

    const closeButton: DialogButton = {
        type: 'BLUE',
        label: 'Close',
        onClick: handleOnClose
    };

    const loadUserActivity = async () => {
        await dispatch(getUserActivity(userId));
    };

    useEffect(() => {
        setUserActivities(userActivity);
    }, [userActivity]);

    useEffect(() => {
        if (!open || !userId) return;
        loadUserActivity();
    }, [open, userId]);

    const renderExpandedActivity = (activity: ActivityTreeNode) => {
        if (showActivityDetails !== activity._id) return;

        return (
            <ActivityDetails key={activity.objectId}>
                <>
                    {activity.updatedKeys?.map((key: any) => (
                        <ActivityField key={key}>
                            <ActivityFieldKey>
                                {formatObjectActivityKey(activity.objectType as keyof RenamedKeysType, key) || ''}
                            </ActivityFieldKey>
                            <ActivityFieldStatus>Updated</ActivityFieldStatus>
                        </ActivityField>
                    ))}
                    {renderChildrenTree(activity?.children || [], true, translate)}
                </>
            </ActivityDetails>
        );
    };

    const renderActivities = () => {
        return Object.entries(userActivities || {}).map(([timestamp, values]) => {
            const types = Object.keys(values);

            return (types as Array<ObjectActivityType>).flatMap((type, index) => {
                if (!values?.[type]) return <></>;

                const activities = values[type] || [];

                return activities.map((activity) => {
                    const isOpen = showActivityDetails === activity._id;
                    const [date, hours] = getDateAndTime(parseInt(timestamp)).split(', ');
                    return (
                        <ActivityContainer key={activity._id}>
                            <UserActivityInfo
                                $withArrow={activity.type === ObjectActivityTypes.UPDATED}
                                $open={isOpen}
                                onClick={() => {
                                    if (activity.type !== ObjectActivityTypes.UPDATED) return;
                                    handleArrowClick(activity._id);
                                }}
                            >
                                <UserActivityFieldTitle>
                                    <ObjectTypeIcon>{getIconByObjectType(activity.objectType as ObjectType)}</ObjectTypeIcon>
                                    <TruncatedText>{translate(activity.objectName || '') || EMPTY_WORD_STRING}</TruncatedText>
                                </UserActivityFieldTitle>
                                <UserActivityDetails>
                                    <UserActivityField>{_.startCase(activity.type)}</UserActivityField>
                                    <UserActivityField>{date}</UserActivityField>
                                    <UserActivityField>{hours}</UserActivityField>
                                </UserActivityDetails>

                                {activity.type === ObjectActivityTypes.UPDATED && (
                                    <ArrowIcon>
                                        <SVGInline src={isOpen ? icons.arrowUpIcon : icons.arrowDownIcon} />
                                    </ArrowIcon>
                                )}
                            </UserActivityInfo>
                            {renderExpandedActivity(activity)}
                        </ActivityContainer>
                    );
                });
            });
        });
    };

    if (!open || loading) return null;

    return (
        <GenericDialog
            onClose={handleOnClose}
            type={DialogTypes.Form}
            title={title}
            actionButtons={[closeButton]}
            circlesSlugOptions={{
                default: CIRCLE_SLUGS['users']
            }}
        >
            <ActivityDialogInfo>Last 7 Days</ActivityDialogInfo>

            <ActivitiesWrapper>
                {!userActivities || _.isEmpty(userActivities) ? (
                    <ActivityDialogInfo>No activity in the last 7 days</ActivityDialogInfo>
                ) : (
                    renderActivities()
                )}
            </ActivitiesWrapper>
        </GenericDialog>
    );
};

export default UserActivityDialog;
