import React, { useEffect, useState } from 'react';
import GenericDialog, { DialogButton, DialogTextField, DialogTypes } from '../../Dialog/GenericDialog';
import { generateUID } from '../../../../utils/fnGenerator';
import { AddLinkDialogStyles } from '../Sidebar.css';
import { validator } from '../../../../utils/fnValidator';
import { IconsSelect } from '../../Select/IconsSelect';

export type ExtraLink = {
    id: string;
    name: string;
    url: string;
    icon: string;
};
export type AddLinkProps = {
    open: boolean;
    onClose: any;
    onSave: any;
    extraLink?: ExtraLink;
};

const AddLinkDialog: React.FC<AddLinkProps> = ({ open, onClose, onSave, extraLink }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [icon, setIcon] = useState<any>('');
    const [errors, setErrors] = useState<{ name?: string; url?: string; icon?: string }>({});

    useEffect(() => {
        if (extraLink) {
            setName(extraLink.name);
            setUrl(extraLink.url);
            setIcon(extraLink.icon);
        }
    }, [extraLink]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const deleteError = (key: string) => {
        setErrors((errors: any) => {
            let newErrors = { ...errors };
            delete newErrors[key];
            return newErrors;
        });
    };
    const handleCloseClick = () => {
        onClose();
        setIcon('');
        setUrl('');
        setName('');
        setErrors({});
    };
    const validateNewLink = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true, minLength: 3 }, name);
        newErrors.url = validator({ required: true }, url);
        newErrors.icon = validator({ required: true }, icon);

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };
    const handleAddLinkClick = () => {
        const newLink: ExtraLink = {
            id: extraLink?.id || generateUID(),
            name: name,
            url: url,
            icon
        };
        if (!validateNewLink()) return;
        setIcon('');
        setUrl('');
        setName('');
        setErrors({});
        onSave(newLink);
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleAddLinkClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleCloseClick
    };

    if (!isOpen) {
        return null;
    }
    const title = !extraLink ? 'Add Link' : 'Edit Link';

    return (
        <GenericDialog
            title={title}
            type={DialogTypes.Form}
            onClose={handleCloseClick}
            actionButtons={[cancelButton, saveButton]}
            style={AddLinkDialogStyles}
        >
            <DialogTextField
                label="Name"
                placeholder="Add link name"
                error={errors?.name}
                value={name}
                onChange={(evt: any) => {
                    deleteError('name');
                    setName(evt.target.value);
                }}
            />
            <DialogTextField
                label="URL"
                placeholder="Paste link"
                error={errors.url}
                value={url}
                onChange={(evt: any) => {
                    deleteError('url');
                    setUrl(evt.target.value);
                }}
                isUrl
            />

            <IconsSelect
                value={icon}
                error={errors?.icon}
                onChange={(value) => {
                    deleteError('icon');
                    setIcon(value);
                }}
                hideNoIconOption
            />
        </GenericDialog>
    );
};

export default AddLinkDialog;
