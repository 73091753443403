import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { pagesState } from '../../../redux/slices/pagesSlice';
import GenericDialog, {
    DialogButton,
    DialogDropdownSingle,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { validator } from '../../../utils/fnValidator';
import { IconsSelect } from '../../common/Select/IconsSelect';
import { capitalizeAndSplitCamelCaseString } from '../../../utils/fnString';
import { MenuItemTypes, MenuTypes } from '../../../types/Menu';
import { ActionFieldsToggle } from '../../Items/Items.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import {
    ApplicationFieldLabelValues,
    ApplicationFieldTooltips,
    ApplicationFieldTooltipsValues,
    ApplicationValues
} from '../../Applications/ApplicationDialog';
import { ApplicationsState } from '../../../redux/slices/applicationsSlice';
import { useNavigate } from 'react-router-dom';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { getPagesSelectOptions } from '../../../utils/fnDialogs';
import ApplicationSelect from '../../Applications/ApplicationSelect';
import useTranslation from '../../../hooks/useTranslation';
import { DropdownOptionWrapper, TruncatedText, VerticalSpacer } from '../../../style/styled-components/reusable.css';
import { fetchSubmenus, menusState } from '../../../redux/slices/menusSlice';
import ObjectNameTooltip from '../../common/Tooltips/ObjectNameTooltip/ObjectNameTooltip';
import { EMPTY_WORD_STRING } from '../../../utils/Globals';
import { ObjectTypes } from '../../../types/Object';
import TranslationTooltip from '../../common/TranslationTooltip/TranslationTooltip';
import {
    PublishedStatusIconHolder,
    ValueToDisplayIconsPlaceholder,
    ValueToDisplayWrapper
} from '../../PageEdit/Dialogs/UseExistingDialog.css';
import { renderPublishStatusIcon } from '../../../utils/fnPublish';

type NewMenuItemProps = {
    open: boolean;
    menuItem?: any;
    newPageId?: string;
    onClose: () => void;
    onSave: (item: any) => void;
    onAddNewItem?: () => void;
    showOnlyTypePage?: boolean;
    withoutNewOption?: boolean;
    menuType: MenuTypes;
};

export const NewMenuItemDialog: FC<NewMenuItemProps> = ({
    open,
    menuItem,
    newPageId,
    withoutNewOption,
    onClose,
    onSave,
    onAddNewItem,
    showOnlyTypePage,
    menuType
}) => {
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState('');
    const [url, setUrl] = useState('');
    const [icon, setIcon] = useState<any>('');
    const [itemType, setItemType] = useState<MenuItemTypes | ''>('');
    const [pageId, setPageId] = useState<string>('');
    const [enableSubmenu, setEnableSubmenu] = useState(false);
    const [submenuId, setSubmenuId] = useState<string>('');
    const [errors, setErrors] = useState<{
        type?: string;
        page?: string;
        submenu?: string;
        package?: string;
        title?: string;
        subtitle?: string;
        url?: string;
        selectedApplication?: string;
        [key: string]: any;
    }>({});

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { translate } = useTranslation();
    const { allPages: pages, loadingAllPages: pagesLoading }: pagesState = useAppSelector((state) => state.pages);
    const { menus, loading: submenusLoading, allSubmenus, menuTypes }: menusState = useAppSelector((state) => state.menus);
    const { applications }: ApplicationsState = useAppSelector((state) => state.applications);
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);

    // APPLICATIONS
    const [selectedApplication, setSelectedApplication] = useState('');
    const [applicationFieldValues, setApplicationFieldValues] = useState<any>({});
    const [optionsExpanded, setOptionsExpanded] = useState(false);

    const isSubmenuAvailable = menuTypes.some((mt) => mt.value === MenuTypes.SUBMENU);

    const onHandleSave = () => {
        if (!validateMenuItem()) return;

        let newMenuItem: any = {
            title: title
        };

        switch (menuType) {
            case MenuTypes.CUSTOMER_SERVICE:
                newMenuItem.subtitle = subtitle;
                newMenuItem.url = url;
                break;
            case MenuTypes.SUBMENU:
            case MenuTypes.MAIN:
                if (!itemType) return;
                newMenuItem.type = itemType;
                newMenuItem.icon = icon;
                if (itemType === MenuItemTypes.APPLICATION) {
                    newMenuItem = { ...newMenuItem, ...applicationFieldValues, appName: selectedApplication };
                }
                if (itemType === MenuItemTypes.PAGE) {
                    newMenuItem.pageId = pageId;
                    newMenuItem.submenuId = submenuId;
                }
                break;
            default:
                break;
        }
        onSave(newMenuItem);
        onHandleClose();
    };
    const onHandleClose = () => {
        onClose && onClose();
        setSelectedApplication('');
        setApplicationFieldValues({});
        setOptionsExpanded(false);
        setTitle('');
        setItemType('');
        setPageId('');
        setSubmenuId('');
        setEnableSubmenu(false);
        setErrors({});
        setIcon('');
        setSubtitle('');
        setUrl('');
    };

    const loadSubmenus = async (projectId: string) => {
        return await dispatch(fetchSubmenus({ projectId })).unwrap();
    };

    const validateMenuItem = () => {
        const newErrors = { ...errors };

        switch (menuType) {
            case MenuTypes.SUBMENU:
            case MenuTypes.MAIN:
                newErrors.type = validator({ required: true }, itemType);
                if (itemType === MenuItemTypes.PAGE) {
                    if (enableSubmenu) {
                        newErrors.submenu = validator({ required: true }, submenuId);
                    } else {
                        newErrors.page = validator({ required: true }, pageId);
                    }
                }
                if (itemType === MenuItemTypes.APPLICATION) {
                    let valuesCount = Object.values(applicationFieldValues).filter((value: any) => !!value).length;
                    Object.keys(applicationFieldValues).forEach((key: string) => {
                        newErrors[key] = valuesCount === 0 ? 'At least one field needs to be filled' : '';
                    });

                    if (valuesCount === 0) {
                        newErrors['selectedApplication'] = validator({ required: true }, selectedApplication);
                    }
                }
                break;
            case MenuTypes.CUSTOMER_SERVICE:
                newErrors.title = validator({ required: true }, title);
                newErrors.subtitle = validator({ required: true }, subtitle);
                newErrors.url = validator({ required: true }, url);
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    useEffect(() => {
        if (!open) return;
        if (!allSubmenus.length) {
            activeProjectId && loadSubmenus(activeProjectId);
        }
    }, [open]);

    useEffect(() => {
        if (!!newPageId) {
            setPageId(newPageId);
        }
    }, [newPageId]);

    useEffect(() => {
        if (menuItem) {
            setTitle(menuItem.title);
            setIcon(menuItem.icon);
            setItemType(menuItem.type);
            menuItem.type === MenuItemTypes.APPLICATION && setSelectedApplication(menuItem.appName || '');
            menuItem.type === MenuItemTypes.APPLICATION &&
                setApplicationFieldValues(
                    Object.keys(ApplicationFieldTooltipsValues).reduce((obj, key) => ({ ...obj, [key]: menuItem[key] || '' }), {})
                );

            if (menuItem.type === MenuItemTypes.PAGE) {
                setPageId(menuItem.pageId || '');
                setEnableSubmenu(!!menuItem.submenuId);
                setSubmenuId(menuItem.submenuId || '');
            }
            setSubtitle(menuItem.subtitle || '');
            setUrl(menuItem.url || '');
        }
    }, [menuItem]);

    useEffect(() => {
        if (!menuItem || menuItem.type !== MenuItemTypes.APPLICATION) return;
        // the options should be expanded if the app fields are overidden
        if (!selectedApplication && Object.values(applicationFieldValues).some((val) => !!val)) {
            setOptionsExpanded(true);
        }
    }, [menuItem, selectedApplication, applicationFieldValues]);

    const checkAppExistsAndReturnKey = (values: ApplicationValues) => {
        for (const appKey in applications) {
            const isSelected = Object.keys(applications[appKey]).every(
                (key) => applications[appKey][key as keyof ApplicationValues] === `${values[key as keyof ApplicationValues]}`.trim()
            );
            if (isSelected) {
                return appKey;
            }
        }
        return '';
    };

    const saveButton: DialogButton = {
        label: menuItem ? 'Save' : 'Create',
        type: 'BLUE',
        disabled: pagesLoading || submenusLoading,
        onClick: onHandleSave
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onHandleClose
    };

    const typeOptions = showOnlyTypePage
        ? [{ label: capitalizeAndSplitCamelCaseString(MenuItemTypes.PAGE), value: MenuItemTypes.PAGE }]
        : Object.values(MenuItemTypes).map((type) => {
              return {
                  label: capitalizeAndSplitCamelCaseString(type),
                  value: type
              };
          });

    const pageOptions = getPagesSelectOptions(pages, activeProjectId || '', navigate, translate, true);

    const submenuOptions = useMemo(() => {
        return allSubmenus.map((m) => {
            return {
                value: m._id,
                label: (
                    <DropdownOptionWrapper>
                        <TruncatedText>
                            <ObjectNameTooltip id={m._id} name={translate(m.name || EMPTY_WORD_STRING)} type={ObjectTypes.MENUS} />
                        </TruncatedText>
                        <div>
                            <TranslationTooltip translationKey={m.name} />
                        </div>
                        <PublishedStatusIconHolder>{renderPublishStatusIcon(m.publishStatus, m.publishAt)}</PublishedStatusIconHolder>
                    </DropdownOptionWrapper>
                ),
                valueForSearch: translate(m.name || EMPTY_WORD_STRING),
                valueToDisplay: (
                    <ValueToDisplayWrapper>
                        {translate?.(m.name) || m.name || EMPTY_WORD_STRING}
                        <ValueToDisplayIconsPlaceholder />
                    </ValueToDisplayWrapper>
                )
            };
        });
    }, [allSubmenus]);

    const pageValue = pageOptions.find((page: any) => page.value === pageId);
    const submenuValue = submenuOptions.find((sm) => sm.value === submenuId);
    const typeValue = typeOptions.find((type: any) => type.value === itemType);

    const renderFieldsForTypePage = () => (
        <>
            <DialogDropdownSingle
                options={pageOptions}
                onChange={(e: any) => {
                    setPageId(e.value);
                    setErrors(_.omit(errors, 'page'));
                }}
                withTopMargin
                value={pageValue}
                placeholder={'Page Selection'}
                error={errors.page}
                isDisabled={pagesLoading}
                newOption={
                    !withoutNewOption
                        ? {
                              name: 'page',
                              onClick: () => {
                                  onAddNewItem?.();
                              }
                          }
                        : undefined
                }
                optional={enableSubmenu}
                clearable={enableSubmenu}
            />
            <VerticalSpacer $height={8} />
            {menuType === MenuTypes.MAIN && isSubmenuAvailable && (
                <>
                    <DialogToggleButton
                        checked={enableSubmenu}
                        toggleCallback={() => {
                            if (enableSubmenu) {
                                setEnableSubmenu(false);
                                setSubmenuId('');
                                setErrors(_.omit(errors, 'submenu'));
                            } else {
                                setEnableSubmenu(true);
                                setErrors(_.omit(errors, 'page'));
                            }
                        }}
                        text={'Enable Submenu'}
                        tooltipText={'menu_enable_submenu'}
                    />
                    {enableSubmenu && (
                        <DialogDropdownSingle
                            options={submenuOptions}
                            onChange={(e: any) => {
                                setSubmenuId(e.value);
                                setErrors(_.omit(errors, 'submenu'));
                            }}
                            withTopMargin
                            value={submenuValue}
                            labelText={'Submenu Selection'}
                            placeholder={submenusLoading ? 'Loading...' : 'Submenu Selection'}
                            error={errors.submenu}
                            isDisabled={submenusLoading}
                        />
                    )}
                </>
            )}
        </>
    );

    const renderFieldsForTypeApplication = () => {
        const fields = [];

        fields.push(
            <ApplicationSelect
                selectedApplication={selectedApplication}
                onSelectedApplicationChange={(newSelectedApp: string) => setSelectedApplication(newSelectedApp)}
                errors={errors}
                setErrors={setErrors}
                onApplicationFieldValuesChange={(newApplicationValues?: ApplicationValues) =>
                    setApplicationFieldValues(newApplicationValues || {})
                }
            />
        );

        fields.push(
            <ActionFieldsToggle onClick={() => setOptionsExpanded(!optionsExpanded)} key={'expand_toggle'}>
                {`${!optionsExpanded ? 'Show' : 'Hide'} Fields`}{' '}
                <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
            </ActionFieldsToggle>
        );

        optionsExpanded &&
            Object.keys(ApplicationFieldTooltipsValues).forEach((field) => {
                const fieldName: keyof ApplicationFieldTooltips = field in ApplicationFieldTooltipsValues ? (field as any) : '';

                fields.push(
                    <DialogTextField
                        key={`param_${field}`}
                        value={applicationFieldValues[field] || ''}
                        onChange={(e: any) => {
                            const newValues = { ...applicationFieldValues, [field]: e.target.value };
                            setSelectedApplication(checkAppExistsAndReturnKey(newValues));
                            setApplicationFieldValues(newValues);
                            setErrors(_.omit(errors, Object.keys(ApplicationFieldTooltipsValues)));
                        }}
                        toolTipText={ApplicationFieldTooltipsValues[fieldName]}
                        label={ApplicationFieldLabelValues[fieldName]}
                        error={errors?.[field]}
                        withTopMargin
                        isUrl
                    />
                );
            });
        return fields;
    };

    if (!open) return null;

    const renderTypeSpecificFields = () => {
        switch (menuType) {
            case MenuTypes.CUSTOMER_SERVICE:
                return (
                    <>
                        <DialogTextField
                            placeholder="Menu item Subtitle"
                            label="Menu item Subtitle"
                            value={subtitle}
                            onChange={(e: any) => {
                                setSubtitle(e.target.value);
                                setErrors(_.omit(errors, 'subtitle'));
                            }}
                            error={errors?.subtitle}
                            withTranslations
                            withTopMargin
                        />
                        <DialogTextField
                            placeholder="Menu item Url"
                            label="Menu item Url"
                            value={url}
                            onChange={(e: any) => {
                                setUrl(e.target.value);
                                setErrors(_.omit(errors, 'url'));
                            }}
                            error={errors?.url}
                            withTopMargin
                            isUrl
                        />
                    </>
                );
            case MenuTypes.MAIN:
            case MenuTypes.SUBMENU:
                return (
                    <>
                        <DialogDropdownSingle
                            options={typeOptions}
                            onChange={(e: any) => {
                                setItemType(e.value);
                                setErrors({});
                                setSubmenuId('');
                                setEnableSubmenu(false);
                                setPageId('');
                                setApplicationFieldValues({});
                                setSelectedApplication('');
                            }}
                            value={typeValue}
                            placeholder={'Menu Component'}
                            error={errors.type}
                            withTopMargin
                        />{' '}
                        {itemType === MenuItemTypes.PAGE && renderFieldsForTypePage()}
                        {itemType === MenuItemTypes.APPLICATION && renderFieldsForTypeApplication()}
                        <IconsSelect value={icon} onChange={(value) => setIcon(value)} />
                    </>
                );
        }
    };

    return (
        <>
            <GenericDialog
                type={DialogTypes.Form}
                title={`${menuItem ? ` Edit` : `Create`}  Menu Item`}
                actionButtons={[cancelButton, saveButton]}
                onClose={onHandleClose}
                circlesSlugOptions={{ default: CIRCLE_SLUGS.menus }}
            >
                <DialogTextField
                    placeholder="Menu item Title"
                    label="Menu item Title"
                    value={title}
                    onChange={(e: any) => {
                        setTitle(e.target.value);
                        setErrors(_.omit(errors, 'title'));
                    }}
                    error={errors?.title}
                    withTranslations
                />
                {renderTypeSpecificFields()}
            </GenericDialog>
        </>
    );
};
