import { contentSourceTypes } from '../types/Item';
import { Service } from './Services';
import { supportedResolutionsType } from '../components/Items/Dialogs/BackgroundDialog';

export const FILTER_PARAM = 'filter';
export const BODY_DELIMITER = '$BODY=';

export const appendQueryParams = (url: string, queries: { [key: string]: boolean | number | string | undefined }): string => {
    let finalUrl = url;

    Object.keys(queries).forEach((queryKey) => {
        if (typeof queries[queryKey] !== 'undefined') {
            finalUrl += !finalUrl.includes('?') ? '?' : '&';
            finalUrl += `${queryKey}=${queries[queryKey]}`;
        }
    });

    return finalUrl;
};

export const createDynamicUrl = (
    services: Service[],
    service: string,
    method?: string,
    params?: any,
    pathParams?: any,
    filterParams?: any,
    bodyParams?: any,
    selectedSubService?: string
) => {
    const serviceObj = services.find((s) => s.key === service);
    const serviceURL = serviceObj?.url || '';
    if (!method) {
        return serviceURL;
    }
    let methodURL =
        serviceObj?.methods.find((m) => (selectedSubService ? m.subService === selectedSubService && m.key === method : m.key === method))
            ?.url || '';

    if (pathParams) {
        Object.keys(pathParams).forEach((elem) => {
            methodURL = methodURL.replace(`:${elem}`, pathParams[elem]);
        });
    }

    const paramsKeys = Object.keys(params || {}).filter((key) => key !== FILTER_PARAM);
    let append = '';

    if (filterParams && Object.keys(filterParams)?.length) {
        let filterQuery = !!append ? '&' : '';

        Object.keys(filterParams).forEach((filter: any, index: number) => {
            if (filterParams[filter] && !!filterParams[filter].length) {
                filterQuery += !!index ? `&filter=${filter}[${filterParams[filter]}]` : `filter=${filter}[${filterParams[filter]}]`;
            }
        });
        append += filterQuery;
    }

    paramsKeys.forEach((p) => {
        const value = Array.isArray(params[p]) ? params[p].join(',') : params[p];
        append += !!append ? `&${p}=${value}` : `${p}=${value}`;
    });

    if (Object.keys(bodyParams || {}).length) {
        let bodyString = BODY_DELIMITER;
        Object.keys(bodyParams)
            .sort()
            .forEach((key, index) => {
                if (key === 'sort' && bodyParams[key] === 'notSorted' && Array.isArray(bodyParams['filter.id'])) {
                    bodyString += !!index ? `&orderBy=${bodyParams['filter.id']}` : `orderBy=${bodyParams['filter.id']}`;
                    return;
                }
                bodyString += !!index ? `&${key}=${bodyParams[key]}` : `${key}=${bodyParams[key]}`;
            });
        append += bodyString;
    }

    return serviceURL + methodURL + (append ? `?${append}` : '');
};

export const parseUrlByService = (url: string, service: string, noOfItems?: number) => {
    switch (service) {
        case contentSourceTypes.ALLENTE_GATEWAY_STB:
            return (
                `${url.replace(':countryCode', 'no')}` +
                (url.includes('?') ? '&' : '?') +
                `languageCode=en&offset=0&limit=${typeof noOfItems === 'number' ? noOfItems : 999}`
            );
        case contentSourceTypes.STINGRAY_KARAOKE:
            return url + (url.includes('?') ? '&' : '?') + `size=${typeof noOfItems === 'number' ? noOfItems : 999}`;
        case contentSourceTypes.YOUTUBE:
            return url + `&part=snippet&maxResults=${typeof noOfItems === 'number' ? noOfItems : 50}`;
        case contentSourceTypes.ALLENTE_GATEWAY:
        case contentSourceTypes.ALLENTE_GATEWAY_STAGING:
            return url + `&language=en&limit=${typeof noOfItems === 'number' ? noOfItems : 999}`;
        default:
            return url;
    }
};

export const extractFileNameFromAzureURL = (azureAccount: string, azureContainer: string, url: string) => {
    return url.split(`https://${azureAccount}.blob.core.windows.net/${azureContainer}/`).pop() || '';
};

export const getFileUrl = (azureAccount: string, azureContainer: string, path: string) => {
    const fullUrl = `https://${azureAccount}.blob.core.windows.net/${azureContainer}/${path}`;

    return fullUrl;
};

export const buildYouboraFilter = (filterObject: any) => {
    const filterRules: any = {};
    Object.keys(filterObject).forEach((key) => {
        filterRules[key.toLowerCase()] = filterObject[key];
    });

    return encodeURIComponent(JSON.stringify([{ name: '', rules: filterRules }]));
};

export const isOpenFileUrl = (imgixBaseUrl: string, url: string) => {
    return !url.includes(imgixBaseUrl);
};

export const getSearchParam = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
};

export const isBackgroundAppliedForAll = (imgixBaseUrl: string, background: supportedResolutionsType | undefined) => {
    if (!background) return false;
    const values = Object.values(background);
    if (!values.length) return false;
    const firstUrl = decodeURIComponent(values[0]);
    const isOpenUrl = isOpenFileUrl(imgixBaseUrl, firstUrl);
    const firstFileName = !isOpenUrl ? firstUrl.split(imgixBaseUrl)[1].split('?')[0] : firstUrl.split('/').pop();

    return (
        !values
            .filter((_, index) => index > 0)
            .some((value) => {
                const url = decodeURIComponent(value);
                const isOpenUrl = isOpenFileUrl(imgixBaseUrl, url);
                const fileName = !isOpenUrl ? url.split(imgixBaseUrl)[1].split('?')[0] : url.split('/').pop();

                return fileName !== firstFileName;
            }) && values.length === 4
    );
};
